.ant-upload-list-picture-card .ant-tooltip-inner {
  display: none;
}

.main-header-container {
  align-items: center !important;
  height: inherit;
  justify-content: space-between;
}

.page-content {
  margin-top: 2em;
}

.main-header {
  padding: 0 !important;
  margin: 0 !important;
  align-items: center !important;
  height: inherit;
  justify-content: flex-end !important;
  margin-right: 2em !important;
}

.main-header > .qr-one-container {
  display: flex;
  align-items: center !important;
  margin-left: 20px;
}
.main-header > .logout {
  cursor: pointer;
}

.main-header > .qr-one-container > .qr-one {
  padding: 0 !important;
  margin: 0 !important;
}

.main-header > .qr-one-container > p {
  margin: 0 !important;
  padding: 0 !important;
  line-height: normal;
  margin-left: 10px !important;
}

.mb-1 {
  margin-bottom: 1rem;
}

@media screen and (max-width: 576px) {
  .main-header {
    margin-right: 10px !important;
  }

  .main-header > .qr-one-container > p {
    font-size: 0.95em;
  }

  .main-header > .qr-one-container > .qr {
    display: none;
  }

  .main-header > .qr-one-container > .qr-text {
    display: none;
  }

  .ant-layout-header {
    position: fixed;
    z-index: 1000;
  }

  .jobs-page {
    margin-top: 70px;
  }
  .jobs-page .page-header-actions {
    margin: 5px 0 0 5px;
  }
  .extra-header.mob-tab .job-info {
    margin-top: 70px;
    flex-direction: column;
  }
  .extra-header.mob-tab .job-info .job-other-info {
    flex-direction: column;
  }
  .extra-header.mob-tab .job-info .job-other-info .job-info-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #dddddd;
    padding: 10px 0;
  }
  .extra-header.mob-tab .job-info .job-other-info .job-info-block span {
    margin-top: 0;
  }
  .extra-header.mob-tab + .page-content .page-sub-header {
    flex-direction: column;
  }
  .extra-header.mob-tab + .page-content .page-sub-header div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .extra-header.mob-tab + .page-content .page-sub-header div button {
    margin: 5px;
  }
  .job-profile-page .page-title {
    justify-content: start;
    padding: 10px 0 10px 15px;
  }
  .job-create-drawer .ant-drawer-content-wrapper {
    width: 320px !important;
  }
  .main-header-container {
    padding: 0 1em;
  }
}
.radioImage {
  display: inline-block;
  margin-bottom: 5px;
  vertical-align: top;
  height: 110px;
}
.radioImage {
  margin-right: 5px;
}
.radioImage .ant-card-body {
  padding: 10px !important;
  height: 110px;
  display: table-cell;
  vertical-align: middle;
}
.radioImage .ant-radio {
  opacity: 0;
}
.ant-radio-wrapper-checked.radioImage .ant-card.ant-card-bordered {
  border-color: #1597ff;
}
.ant-table-action-cell svg#Flat,
.ant-table-action-cell svg#Layer {
  color: inherit !important;
}
.otp-code-input > .ant-input-number-handler-wrap {
  display: none;
}
.otp-page {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.otp-page #otp-form {
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 10px;
  margin: 30px 0;
  box-shadow: 0 0 15px #cecece;
}
.main-message.pop-up-heading {
  font-size: 36px;
}
.pop-up-body {
  color: #828282;
  font-size: 15px;
}

.fallback-loader {
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

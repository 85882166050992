@import url(https://fonts.googleapis.com/css2?family=Roboto);
@import url(https://fonts.googleapis.com/css2?family=Roboto);
@import url(https://fonts.googleapis.com/css2?family=Roboto);
@import url(https://fonts.googleapis.com/css2?family=Roboto);
@import url(https://fonts.googleapis.com/css2?family=Roboto);
:root {
  --color1: #2f80ed;
  --color2: #2d9cdb;
  --color3: #6abb98;
  --color4: #2b2b2b;
  --color5: #828282;
  --color6: #f1b13b;
  --color8: #f2f2f2;
}

.dashboard-container {
  width: 95%;
  margin: 0 auto;
  font-family: 'Roboto', sans-serif;
  padding-top: 4em;
}

.font-bold {
  font-weight: bold;
  letter-spacing: 1px;
}

.in-transit {
  color: #f1b13b;
  color: var(--color6);
}

.completed {
  color: #6abb98;
  color: var(--color3);
}

.not-started {
  color: #828282;
  color: var(--color5);
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2em;
}

.header-title {
  font-size: 1.25em;
  color: #2b2b2b;
  color: var(--color4);
}

.header-timestamp {
  font-size: 1.25em;
  display: flex;
  align-items: center;
}

.dashboard-time {
  padding: 0;
  margin: 0;
  font-size: 0.8em;
}

.statistics-container {
  /* border: 1px solid red; */
  overflow: hidden;
}

/* .job-feed-container {
    border: 1px solid blue;
} */

.main-table {
  margin-top: 2em !important;
  border-radius: 10px !important;
}

.ant-card-head-title {
  color: #2f80ed;
  color: var(--color1);
  font-weight: 800;
}

.stats_job_feed_container {
  flex-direction: row;
  min-height: 700px;
}

.stats-cards-container {
  display: flex;
  /* height: 135px; */
  flex-direction: column;
  align-items: center;
}

.stats-card {
  flex: 1 1;
  margin-right: 0.85em !important;
  border-radius: 10px !important;
  display: flex;
  padding: 0;
}

.stats-card:last-child {
  margin-right: 2em !important;
}

.active-worker-list-header {
  padding: 1.25em 1.75em;
}

.stats-card > .ant-card-body {
  flex: 1 1;
  display: flex;
  flex-direction: row;
  padding: 1em 2em 1.75em 2em;
  margin: 0;
}

.stats-card-data {
  flex: 2 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.stats-card-num {
  font-size: 1.5em;
  padding: 0;
  margin: 0;
  font-weight: bold;
}

.stats-card-data-title {
  font-size: 0.875em;
  padding: 0;
  margin: 0;
  color: #2b2b2b;
  color: var(--color4);
  font-weight: 500;
  margin-right: 0.25em;
}

.stats-card-icon-container {
  flex: 1 1;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stats-card-icon {
  color: #2f80ed;
  color: var(--color1);
  width: 2.5em;
}

.job-feed-card {
  flex: 1 1;
  border-radius: 10px !important;
  height: 100%;
}

.job-feed-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.75em;
}

.job-feed-header-title {
  font-size: 1em;
  color: #2f80ed;
  color: var(--color1);
  font-weight: 600;
  margin: 0;
}

.job-feed-header-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.job-feed-header-icon {
  font-size: 1.25em;
  color: #2d9cdb;
  color: var(--color2);
  cursor: pointer;
  width: 20px;
}

.feed-count-section {
  display: flex;
  /* margin-top: 1.5em; */
}

.feed-count-container {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #2b2b2b;
  color: var(--color4);
}

.feed-count-title {
  font-size: 0.875em;
  padding: 0;
  margin: 0;
}

.feed-count {
  font-size: 1.75em;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

.divider {
  height: 1px;
  background-color: #f2f2f2;
  background-color: var(--color8);
  margin-top: 2em;
}

.timeline-container {
  /* margin-top: 2em; */
  /* padding-top: 1em; */
  max-height: 800px;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.timeline-container::-webkit-scrollbar {
  display: none;
}

.avatar-container {
  margin-left: 1em;
}

.mr-2 {
  margin-right: 5px;
}

/* Hide scrollbar for IE, Edge and Firefox */
.timeline-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.timeline-content {
  display: flex;
  align-items: center;
  padding: 1.25em 1.75em;
  border-bottom: 1px solid #f2f2f2;
  position: relative;
}

.timeline-avatar {
  border: 1px solid #2d9cdb;
  border: 1px solid var(--color2);
  min-width: 2em;
  min-height: 2em;
}

.timeline-details-container {
  margin-left: 1em;
}

.timeline-details-name,
.timeline-details-check-text {
  padding: 0;
  margin: 0;
}

.timeline-details-name {
  font-size: 1em;
  /* font-weight: bold; */
  color: #2b2b2b;
  color: var(--color4);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.timeline-details-secondary-text {
  font-size: 0.8em;
  font-weight: 400;
  color: #828282;
  color: var(--color5);
  padding-top: 5px;
}

.timeline-details-check-text {
  font-size: 1em;
  color: #828282;
  color: var(--color5);
  word-break: break-all;
}

.timeline-details-check-text-link {
  font-weight: 800;
  color: #2d9cdb;
  color: var(--color2);
}

.timeline-timestamp {
  position: absolute;
  right: 1.25em;
  bottom: 0;
  font-size: 0.85em;
  color: #BDBDBD;
}

.map-container {
  margin-right: 2em;
  margin-top: 1em;
  border-radius: 10px;
  flex: 1 1;
  height: 600px;
  display: flex;
  flex-direction: column;
}

.map-container > .job-feed-header {
  background-color: white;
  border-radius: 10px 10px 0 0;
  padding: 1em 2em 1em 1em;
}

.map-main {
  flex: 1 1;
  height: 100%;
  border-radius: 10px;
}

.leaflet-container {
  width: 100%;
  height: 100%;
  border-radius: 0 0 10px 10px;
}

.table-column-title {
  color: #2f80ed;
  color: var(--color1);
  font-weight: 800;
}

.map-filters-container {
  width: 97%;
  border-radius: 10px !important;
  margin-right: 2em;
  margin-top: 1em !important;
  align-self: flex-start;
}

.map-filters-container > .ant-card-body {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  display: flex !important;
  align-items: center;
}

.filter-options-container {
  display: flex;
  width: 100%;
  /* justify-content: space-between; */
}

.checkbox-option {
  display: flex !important;
  align-items: center;
}

.filter-option {
  display: flex;
  align-items: center;
}

.filter-dot {
  max-width: 8px;
  max-height: 8px;
  min-width: 8px;
  min-height: 8px;
  background-color: #828282;
  border-radius: 50%;
  margin-right: 0.4em;
}

.filter-dot--primary {
  background-color: #2d9cdb;
}

.filter-dot--danger {
  background-color: #df5e67;
}

.filter-option-label {
  padding: 0 !important;
  margin: 0 !important;
  font-size: 0.75rem;
}

.map-filters-container > .ant-card-body > .filter-label {
  margin-right: 1em;
}

@media screen and (max-width: 1024px) {
  .stats-card:last-child {
    margin-right: 0;
  }

  .stats-card {
    max-height: 10em;
  }

  .map-container {
    margin-right: 0;
  }

  .statistics-container {
    height: 70vh;
  }

  .job-feed-container {
    margin-top: 2.25em;
  }
}

@media screen and (max-height: 768px) {
  .statistics-container {
    /* height: 100vh; */
    min-height: 60em;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .map-container {
    margin-bottom: 2em;
    margin-right: 0;
  }

  .stats-card:last-child {
    margin-right: 0 !important;
  }

  .statistics-container {
    height: inherit;
  }

  .job-feed-container {
    margin-top: 0.5em;
  }

  .map-filters-container {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .map-container {
    margin-bottom: 0;
    margin-right: 0;
  }

  .stats-card:last-child {
    margin-right: 0 !important;
  }

  .statistics-container {
    height: auto;
  }

  .map-filters-container {
    width: 100%;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .stats-card {
    margin-right: 0.5em;
  }
  .stats-card:last-child {
    margin-right: 0 !important;
  }

  .statistics-container {
    height: auto;
  }

  .map-filters-container {
    width: 100%;
  }
}

@media screen and (max-width: 576px) {
  .statistics-container {
    height: inherit;
    margin-bottom: 1.25em;
  }
  .stats-cards-container {
    display: flex;
    flex-direction: column;
    height: auto;
    height: initial;
  }
  .stats-card {
    margin-right: 0;
    margin-bottom: 2em;
  }
  .header-container {
    flex-direction: column;
  }
  .header-timestamp {
    font-size: 1em;
    display: flex;
    flex-direction: column;
    margin-top: 1.25em;
    background-color: white;
    width: 100%;
    padding: 1em 0;
    border-radius: 5px;
  }
  .dashboard-datepicker {
    margin-top: 0.75em;
  }
  .map-container {
    margin-top: 0;
    max-height: 300px;
  }
  .stats-card-container1 {
    display: none !important;
  }
  .stats-card-container {
    display: none !important;
  }
  .job-feed-container {
    margin-top: 0;
  }
  .job-feed-card {
    border-radius: 5px !important;
  }
  .main-table {
    margin-top: 1em !important;
    border-radius: 5px !important;
  }
  .divider {
    margin-top: 1em;
  }
  .timeline-details-name {
    font-size: 0.85em;
  }
  .timeline-details-check-text {
    font-size: 0.85em;
  }
  .stats-card:last-child {
    margin-right: 0 !important;
  }
  .job-feed-header-title {
    font-size: 1em;
  }
  .job-feed-header-icon {
    height: 21px;
  }
  .work-status-table-title {
    font-size: 0.85em;
  }
  .map-filters-container {
    width: 100%;
    margin-top: 0 !important;
    margin-bottom: 1em !important;
  }
  .map-filters-container > .ant-card-body {
    flex-direction: column;
    align-items: flex-start !important;
  }
  .map-filters-container > .ant-card-body > .filter-label {
    font-weight: bold;
  }
  .filter-options-container {
    flex-wrap: wrap;
  }
  .checkbox-option {
    margin: 0 !important;
    margin-top: 10px !important;
    min-width: 50% !important;
  }
}

.map_pin_job-tooltip_container {
  width: 244px;
  height: 120px;
  padding: 0;
  margin: 0 0.5em;
}

.map_pin_job-tooltip_header,
.map_pin_job_duration_container,
.map_pin_job_user_info_container {
  display: flex;
  align-items: center;
}

.map_pin_job_user_info_container {
  align-items: flex-start;
}

.map_pin_job_user_info {
  display: flex;
  flex-direction: column;
  margin-left: 1em;
}

.map_pin_job_duration_container {
  margin: 0.5em 0;
}

.map_pin_job-tooltip_header {
  justify-content: space-between;
}

.map_pin_job-tooltip_header_title,
.map_pin_job_time,
.map_pin_job_duration_title,
.map_pin_job_duration_text,
.map_pin_job_user_info_name,
.map_pin_job_user_info_addr,
.map_pin_job_user_info_tag {
  padding: 0;
  margin: 0;
  font-size: 1.25em;
}

.map_pin_job_duration_title,
.map_pin_job_duration_text,
.map_pin_job_user_info_addr,
.map_pin_job_user_info_tag {
  font-size: 1.15em;
  color: #2b2b2b;
  color: var(--color4);
}

.map_pin_job-tooltip_header_title,
.map_pin_job_user_info_name {
  font-weight: bold;
}

.dot {
  width: 8px;
  height: 8px;
  background-color: #828282;
  background-color: var(--color5);
  border-radius: 50%;
  margin: 0 0.5em;
}

.truncate {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.date-picker-dashboard > .ant-picker-input > input {
  text-align: center;
  width: 75px;
}

.job-feed-card > .ant-card-body {
  padding: 0 !important;
}

@media (max-width: 576px) {
  .header-container {
    padding: 1em;
    margin: 0;
    margin-top: 1em;
    background-color: white;
    border-radius: 5px;
  }
  .header-container > .header-title {
    padding: 0;
    margin: 0;
  }
}

body .ant-btn-primary {
  border-radius: 4px;
  background: #2d9cdb;
  border-color: #2d9cdb;
}

body .ant-btn-default {
  border-color: #2d9cdb;
  color: #2d9cdb;
  border-radius: 4px;
}
.page-header .page-header-actions {
  display: flex;
  align-items: center;
}

.page-header .page-header-actions .page-header-action,
.page-header .page-header-actions button {
  margin-right: 15px;
}
.page-header .page-header-actions .page-header-action svg {
  color: #bdbdbd;
  font-size: 18px;
  cursor: pointer;
}
.page-header .page-header-actions .ant-input-search {
  width: 100%;
  padding: 6px 18px;
  border: 1px solid #f2f2f2;
  border-radius: 25px;
  box-shadow: 2px 4px 9px rgba(199, 199, 199, 0.5);
  box-sizing: border-box;
}

.page-content {
  padding: 30px;
}

.ant-table-action-cell {
  display: flex;
  align-items: center;
}
.ant-table-action-cell svg {
  color: #bdbdbd;
  font-size: 18px;
}
.ant-table-action-cell span + span {
  margin-left: 15px;
}

.delete-user-modal .ant-modal-body {
  text-align: center;
}
.delete-user-modal .ant-modal-footer {
  border: none;
  padding: 24px;
}
.delete-user-modal p {
  margin: 0;
}
.delete-user-modal .user-info {
  margin: 24px;
}
.delete-user-modal .username {
  margin-top: 16px;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}
.delete-user-modal .main-message {
  max-width: 350px;
  margin: auto;
  font-weight: 500;
  font-size: 18px;
  color: #2d9cdb;
}
.delete-user-modal .ant-modal-footer .ant-btn {
  width: 120px;
  border-radius: 5px;
  border: none;
}
.delete-user-modal .ant-modal-footer .ant-btn-primary {
  background: #2d9cdb;
}

.drawer-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}
.drawer-header h3 {
  color: #2b2b2b;
  font-size: 22px;
  margin: 0;
}
.drawer-header svg {
  color: #bdbdbd;
  font-size: 18px;
}
.drawer-header .action-primary svg {
  color: #2d9cdb;
  margin-right: 15px;
}
.drawer-body .user-avatar {
  text-align: center;
  padding: 30px 0;
}
.drawer-body .user-avatar .avatar-uploader {
  width: auto;
}
.drawer-body .username {
  color: #2b2b2b;
  font-size: 20px;
  font-weight: 500;
  margin-top: 15px;
}
.drawer-body .user-status {
  display: flex;
  align-items: center;
  justify-content: center;
}
.drawer-body .user-status .dot {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.drawer-body .user-status .dot.active {
  background: #6abb98;
}
.drawer-body .user-status .dot.inactive {
  background: #f1b13b;
}
.drawer-body .user-status .dot.suspended {
  background: #df5e67;
}
.drawer-body .user-status span:last-child {
  font-size: 16px;
  color: #2b2b2b;
  margin-left: 10px;
  text-transform: capitalize;
}
.drawer-body .user-info-row {
  padding: 10px;
}
.drawer-body .user-info-row label {
  display: block;
  font-weight: 500;
  font-size: 16px;
  color: #2b2b2b;
}
.drawer-body .user-info-row span {
  display: block;
  font-size: 16px;
  color: #828282;
}
.drawer-body .avatar-uploader .ant-upload-select {
  border: none;
  margin: 0;
  background: transparent;
}

.drawer-body .form-group {
  padding: 6px 0;
}
.drawer-body .form-group > label {
  display: block;
  font-weight: 500;
  font-size: 16px;
  color: #2b2b2b;
}
.drawer-body .form-group > span {
  font-size: 16px;
  color: #2b2b2b;
}
.drawer-body form .ant-btn-block {
  margin-top: 15px;
}
.drawer-body form .ant-btn-primary {
  background: #2d9cdb;
  border-radius: 7px;
  border-color: #2d9cdb;
  height: 42px;
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
}
.drawer-body .password-reset-form {
  margin-top: 30px;
}

.role-perm-heading {
  margin-top: 1em;
  font-weight: bold;
}

.permission-tabs-container {
  margin-top: 0.35em;
  display: flex;
  flex-wrap: wrap;
}

.permission-tab {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 1em !important;
  text-transform: capitalize;
  margin-bottom: 0.5em !important;
}
.radioImage {
  height: 120px !important;
}
.mob-table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin: 15px 0;
  border: 1px solid #dddddd;
}

.mob-table th,
.mob-table td {
  padding: 8px;
}
.mob-table td {
  width: 63%;
}
.mob-table-data,
.mob-table-heading {
  border: 1px solid #dddddd;
  text-align: left;
}

.mob-table-heading {
  color: #2f80ed;
  width: 37%;
}

.mob-table-row:nth-child(even) {
  background-color: #f2f2f2;
}
.mob-table .email-wrap {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-search-container {
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 2em;
  padding: 0.75em 1.5em;
  display: flex;
  justify-content: space-between;
}

.table-search-input {
  padding: 0;
  margin: 0;
  width: 90%;
  border: none;
  outline: none;
}

.table-search-input::-webkit-input-placeholder {
  color: #bdbdbd;
}

.table-search-input:-ms-input-placeholder {
  color: #bdbdbd;
}

.table-search-input::placeholder {
  color: #bdbdbd;
}

.overflow-x-auto {
  overflow-x: auto;
}

.login-main {
  background: #eee;
}
.login-main .login-wrap {
  display: flex;
  min-height: 100vh;
  width: 100%;
}
.login-main .login-wrap .img-side,
.login-main .login-wrap .form-side {
  height: 100vh;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 7%;
  position: relative;
}
.login-main .login-wrap .img-side {
  background: url(/static/media/butlerLoginBg.3d283a7a.jpg) center no-repeat;
  background-size: cover;
}
.login-main .login-wrap .img-side:before {
  background: rgba(0, 0, 0, 0.6);
  content: '';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.login-main .login-wrap .img-side * {
  color: #fff;
  position: relative;
}
.login-main .login-wrap .img-side h1 {
  font-size: 30px;
  font-weight: 600;
}
.login-main .login-wrap .img-side p {
  font-size: 24px;
}

.login-main .login-wrap .form-side {
  background-color: #f1f5f6;
}
.login-main .login-wrap .form-side span.ant-input-affix-wrapper {
  border-radius: 4px;
  height: 50px;
}
.login-main .login-wrap .form-side span.ant-input-affix-wrapper input {
  padding-left: 5px;
  border-color: #dae1e3;
}
.login-main .login-wrap .form-side .ant-col.ant-form-item-label label {
  font-size: 15px;
  color: #4c4d4d;
  line-height: 22px;
  font-weight: 600;
}
.login-main .login-wrap .form-side .ant-col.ant-form-item-label label::before {
  display: none;
}
.login-main .login-wrap .form-side .login-form-input {
  border-radius: 4px;
  height: 50px;
  padding-left: 15px;
  border-color: #dae1e3;
}
.login-main .login-wrap .form-side .ant-typography {
  font-weight: 400;
  line-height: 1.2;
  font-size: 26px;
}
.login-main .login-wrap .form-side .ant-form-item {
  margin-bottom: 12px;
}
.login-main .login-wrap .form-side .checkbox-remember-me label {
  margin-left: 2px;
  font-weight: 600;
}
.login-main .login-wrap .form-side .checkbox-remember-me {
  display: block;
  margin: 10px 0;
  font-size: 14px;
  color: #4c4d4d;
  line-height: 22px;
}
.login-main .login-wrap #logo {
  position: absolute;
  top: 5%;
  width: 160px;
}
.login-main .login-wrap .form-side .login-form-button {
  background-color: #000;
  color: white;
  border-radius: 4px;
  border: 0;
  width: 100%;
  height: 50px;
  margin-top: 10px;
  box-shadow: 0px 5px 15px rgb(0 0 0 / 15%);
  font-weight: 800;
  font-size: 1rem;
}
@media (max-width: 1024px) {
  .login-main .login-wrap #logo {
    top: 5%;
    width: 160px;
  }
  .login-main .login-wrap .img-content {
    margin-top: 10%;
  }
  .login-main .login-wrap .img-side,
  .login-main .login-wrap .form-side {
    padding: 3%;
  }
}
@media (max-width: 786px) {
  .login-main .login-wrap {
    flex-direction: column;
  }
  .login-main .login-wrap .img-content {
    margin-top: 20%;
  }
  .login-main .login-wrap .img-side,
  .login-main .login-wrap .form-side {
    height: auto;
    width: 100%;
  }
}

.expand-parent {
  background-color: #e7f8fe;
}
.expand-parent .ant-table-cell {
  background-color: #e7f8fe;
}
table > tbody > tr.expand-parent:hover {
  background-color: #e7f8fe;
}
.my-table .ant-table-expanded-row > .ant-table-cell {
  padding-left: 0 !important;
}

.my-table
  .ant-table-tbody
  > tr
  > td
  > .ant-table-wrapper:only-child
  .ant-table {
  margin: -16px -16px -16px 0px !important;
}

.loader-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.jobs-page .page-header {
    display: block;
    padding-bottom: 0;
}
.jobs-page .page-header .ant-col {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1 1;
}

.job-detail-drawer .drawer-header {
    padding-bottom: 30px;
}
.job-detail-drawer .drawer-body, .job-detail-drawer .drawer-footer {
    border-top: 1px solid #F2F2F2;
    padding: 15px 5px;
}
.job-detail-drawer .ant-row {
    padding: 15px 0;
}
.job-detail-drawer label {
    display: block;
    font-weight: 500;
    font-size: 16px;
    color: #2B2B2B;
}
.job-detail-drawer .drawer-body span, .job-detail-drawer .drawer-footer span {
    display: block;
    margin-top: 10px;
    font-size: 16px;
    color: #828282;
}
.job-detail-drawer .badge {
    border-radius: 5px;
    padding: 8px 16px;
    color: white;
    margin-right: 15px;
}
.job-detail-drawer .drawer-header .header-actions {
    display: flex;
    align-items: center;
}
.job-detail-drawer .badge.completed {
    background: #BDBDBD;
}
.job-detail-drawer .badge.cancelled {
    background: #DF5E67;
}
.job-detail-drawer .badge.pending_schedule {
    background: #F1B13B;
}
.job-detail-drawer .badge.in_progress {
    background: #6ABB98;
}
.job-detail-drawer .badge.scheduled {
    background: #34A6BD;
}
.limit-title {
    font-size: 0.75em;
    color: #ccc;
    font-style: italic;
}
.job-freq-field-container {
    display: flex;
    align-items: center;
}

.job-freq-field-container > .ant-col > p {
    padding: 0;
    margin: 0;
}
.jobs-page .page-header {
    display: block;
    padding-bottom: 0;
}
.jobs-page .page-header .ant-col {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1 1;
}

.job-detail-drawer .drawer-header {
    padding-bottom: 30px;
}
.job-detail-drawer .drawer-body, .job-detail-drawer .drawer-footer {
    border-top: 1px solid #F2F2F2;
    padding: 15px 5px;
}
.job-detail-drawer .ant-row {
    padding: 15px 0;
}
.job-detail-drawer label {
    display: block;
    font-weight: 500;
    font-size: 16px;
    color: #2B2B2B;
}
.job-detail-drawer .drawer-body span, .job-detail-drawer .drawer-footer span {
    display: block;
    margin-top: 10px;
    font-size: 16px;
    color: #828282;
}
.job-detail-drawer .badge {
    border-radius: 5px;
    padding: 8px 16px;
    color: white;
    margin-right: 15px;
}
.job-detail-drawer .drawer-header .header-actions {
    display: flex;
    align-items: center;
}
.job-detail-drawer .badge.completed {
    background: #BDBDBD;
}
.job-detail-drawer .badge.cancelled {
    background: #DF5E67;
}
.job-detail-drawer .badge.pending_schedule {
    background: #F1B13B;
}
.job-detail-drawer .badge.in_progress {
    background: #6ABB98;
}
.job-detail-drawer .badge.scheduled {
    background: #34A6BD;
}
.job-detail-drawer .badge.repeating {
    background: #6ABB98;
}
.job-detail-drawer .badge.no-repeating {
    background: #DF5E67;
}
.limit-title {
    font-size: 0.75em;
    color: #ccc;
    font-style: italic;
}
.job-freq-field-container {
    display: flex;
    align-items: center;
}

.job-freq-field-container > .ant-col > p {
    padding: 0;
    margin: 0;
}
.react-timekeeper {
  position: absolute !important;
  bottom: 36px;
  z-index: 100;
  left: 0;
}
.css-7uwvmz {
  margin-top: 0 !important;
}
.css-v5mcba {
  margin-top: 0 !important;
}
.css-7thaag {
  margin-top: 0 !important;
}
.css-jwqk3 {
  margin-top: 0 !important;
}
.react-timekeeper__done-button {
  display: none !important;
}
.react-timekeeper__clock {
  overflow: hidden !important;
}
.css-11zmfun {
  margin-top: 3px !important;
}

.vendor-profile-page .vendor-info {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-bottom: 50px;
}

.vendor-profile-page .vendor-info .vendor-avatar {
  padding: 0 15px;
  display: flex;
  align-items: center;
}
.vendor-profile-page .vendor-avatar-info {
  padding-left: 15px;
}
.vendor-profile-page .vendor-avatar-info .vendor-name {
  font-weight: 500;
  font-size: 18px;
  color: #2b2b2b;
  margin-bottom: 5px;
}
.vendor-profile-page .vendor-avatar-info .vendor-id {
  color: #4f4f4f;
}

.vendor-profile-page .vendor-info-block {
  padding: 0 15px;
}
.vendor-profile-page .vendor-info-block label {
  display: block;
  font-weight: 500;
  font-size: 16px;
  color: #2b2b2b;
}
.vendor-profile-page .vendor-info-block span {
  display: block;
  font-size: 16px;
  color: #828282;
  margin-top: 10px;
}

.role-perm-heading{
  margin-top: 1em;
    font-weight: bold;
    text-align: center;
}
.avatar-uploader .ant-upload-select {
    width: 100px;
    height: 100px;
    margin: 0;
    border: none;
    background: transparent;
    cursor: default;
}
.avatar-uploader .ant-upload-select > span.ant-upload {
    padding: 0;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
}
:root {
  --color1: #2f80ed;
  --color2: #2d9cdb;
  --color3: #6abb98;
  --color4: #2b2b2b;
  --color5: #828282;
  --color6: #f1b13b;
  --color8: #f2f2f2;
}

.dashboard-container {
  width: 95%;
  margin: 0 auto;
  font-family: 'Roboto', sans-serif;
  padding-top: 4em;
}

.font-bold {
  font-weight: bold;
  letter-spacing: 1px;
}

.in-transit {
  color: #f1b13b;
  color: var(--color6);
}

.completed {
  color: #6abb98;
  color: var(--color3);
}

.not-started {
  color: #828282;
  color: var(--color5);
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2em;
}

.header-title {
  font-size: 1.25em;
  color: #2b2b2b;
  color: var(--color4);
}

.header-timestamp {
  font-size: 1.25em;
  display: flex;
  align-items: center;
}

.dashboard-time {
  padding: 0;
  margin: 0;
  font-size: 0.8em;
}

.statistics-container {
  /* border: 1px solid red; */
  overflow: hidden;
}

/* .job-feed-container {
    border: 1px solid blue;
} */

.main-table {
  margin-top: 2em !important;
  border-radius: 10px !important;
}

.ant-card-head-title {
  color: #2f80ed;
  color: var(--color1);
  font-weight: 800;
}

.stats_job_feed_container {
  flex-direction: row;
  min-height: 700px;
}

.stats-cards-container {
  display: flex;
  /* height: 135px; */
  flex-direction: column;
  align-items: center;
}

.stats-card {
  flex: 1 1;
  margin-right: 0.85em !important;
  border-radius: 10px !important;
  display: flex;
  padding: 0;
}

.stats-card:last-child {
  margin-right: 2em !important;
}

.active-worker-list-header {
  padding: 1.25em 1.75em;
}

.stats-card > .ant-card-body {
  flex: 1 1;
  display: flex;
  flex-direction: row;
  padding: 1em 2em 1.75em 2em;
  margin: 0;
}

.stats-card-data {
  flex: 2 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.stats-card-num {
  font-size: 1.5em;
  padding: 0;
  margin: 0;
  font-weight: bold;
}

.stats-card-data-title {
  font-size: 0.875em;
  padding: 0;
  margin: 0;
  color: #2b2b2b;
  color: var(--color4);
  font-weight: 500;
  margin-right: 0.25em;
}

.stats-card-icon-container {
  flex: 1 1;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stats-card-icon {
  color: #2f80ed;
  color: var(--color1);
  width: 2.5em;
}

.job-feed-card {
  flex: 1 1;
  border-radius: 10px !important;
  height: 100%;
}

.job-feed-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.75em;
}

.job-feed-header-title {
  font-size: 1em;
  color: #2f80ed;
  color: var(--color1);
  font-weight: 600;
  margin: 0;
}

.job-feed-header-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.job-feed-header-icon {
  font-size: 1.25em;
  color: #2d9cdb;
  color: var(--color2);
  cursor: pointer;
  width: 20px;
}

.feed-count-section {
  display: flex;
  /* margin-top: 1.5em; */
}

.feed-count-container {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #2b2b2b;
  color: var(--color4);
}

.feed-count-title {
  font-size: 0.875em;
  padding: 0;
  margin: 0;
}

.feed-count {
  font-size: 1.75em;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

.divider {
  height: 1px;
  background-color: #f2f2f2;
  background-color: var(--color8);
  margin-top: 2em;
}

.timeline-container {
  /* margin-top: 2em; */
  /* padding-top: 1em; */
  max-height: 800px;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.timeline-container::-webkit-scrollbar {
  display: none;
}

.avatar-container {
  margin-left: 1em;
}

.mr-2 {
  margin-right: 5px;
}

/* Hide scrollbar for IE, Edge and Firefox */
.timeline-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.timeline-content {
  display: flex;
  align-items: center;
  padding: 1.25em 1.75em;
  border-bottom: 1px solid #f2f2f2;
  position: relative;
}

.timeline-avatar {
  border: 1px solid #2d9cdb;
  border: 1px solid var(--color2);
  min-width: 2em;
  min-height: 2em;
}

.timeline-details-container {
  margin-left: 1em;
}

.timeline-details-name,
.timeline-details-check-text {
  padding: 0;
  margin: 0;
}

.timeline-details-name {
  font-size: 1em;
  /* font-weight: bold; */
  color: #2b2b2b;
  color: var(--color4);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.timeline-details-secondary-text {
  font-size: 0.8em;
  font-weight: 400;
  color: #828282;
  color: var(--color5);
  padding-top: 5px;
}

.timeline-details-check-text {
  font-size: 1em;
  color: #828282;
  color: var(--color5);
  word-break: break-all;
}

.timeline-details-check-text-link {
  font-weight: 800;
  color: #2d9cdb;
  color: var(--color2);
}

.timeline-timestamp {
  position: absolute;
  right: 1.25em;
  bottom: 0;
  font-size: 0.85em;
  color: #BDBDBD;
}

.map-container {
  margin-right: 2em;
  margin-top: 1em;
  border-radius: 10px;
  flex: 1 1;
  height: 600px;
  display: flex;
  flex-direction: column;
}

.map-container > .job-feed-header {
  background-color: white;
  border-radius: 10px 10px 0 0;
  padding: 1em 2em 1em 1em;
}

.map-main {
  flex: 1 1;
  height: 100%;
  border-radius: 10px;
}

.leaflet-container {
  width: 100%;
  height: 100%;
  border-radius: 0 0 10px 10px;
}

.table-column-title {
  color: #2f80ed;
  color: var(--color1);
  font-weight: 800;
}

.map-filters-container {
  width: 97%;
  border-radius: 10px !important;
  margin-right: 2em;
  margin-top: 1em !important;
  align-self: flex-start;
}

.map-filters-container > .ant-card-body {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  display: flex !important;
  align-items: center;
}

.filter-options-container {
  display: flex;
  width: 100%;
  /* justify-content: space-between; */
}

.checkbox-option {
  display: flex !important;
  align-items: center;
}

.filter-option {
  display: flex;
  align-items: center;
}

.filter-dot {
  max-width: 8px;
  max-height: 8px;
  min-width: 8px;
  min-height: 8px;
  background-color: #828282;
  border-radius: 50%;
  margin-right: 0.4em;
}

.filter-dot--primary {
  background-color: #2d9cdb;
}

.filter-dot--danger {
  background-color: #df5e67;
}

.filter-option-label {
  padding: 0 !important;
  margin: 0 !important;
  font-size: 0.75rem;
}

.map-filters-container > .ant-card-body > .filter-label {
  margin-right: 1em;
}

@media screen and (max-width: 1024px) {
  .stats-card:last-child {
    margin-right: 0;
  }

  .stats-card {
    max-height: 10em;
  }

  .map-container {
    margin-right: 0;
  }

  .statistics-container {
    height: 70vh;
  }

  .job-feed-container {
    margin-top: 2.25em;
  }
}

@media screen and (max-height: 768px) {
  .statistics-container {
    /* height: 100vh; */
    min-height: 60em;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .map-container {
    margin-bottom: 2em;
    margin-right: 0;
  }

  .stats-card:last-child {
    margin-right: 0 !important;
  }

  .statistics-container {
    height: inherit;
  }

  .job-feed-container {
    margin-top: 0.5em;
  }

  .map-filters-container {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .map-container {
    margin-bottom: 0;
    margin-right: 0;
  }

  .stats-card:last-child {
    margin-right: 0 !important;
  }

  .statistics-container {
    height: auto;
  }

  .map-filters-container {
    width: 100%;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .stats-card {
    margin-right: 0.5em;
  }
  .stats-card:last-child {
    margin-right: 0 !important;
  }

  .statistics-container {
    height: auto;
  }

  .map-filters-container {
    width: 100%;
  }
}

@media screen and (max-width: 576px) {
  .statistics-container {
    height: inherit;
    margin-bottom: 1.25em;
  }
  .stats-cards-container {
    display: flex;
    flex-direction: column;
    height: auto;
    height: initial;
  }
  .stats-card {
    margin-right: 0;
    margin-bottom: 2em;
  }
  .header-container {
    flex-direction: column;
  }
  .header-timestamp {
    font-size: 1em;
    display: flex;
    flex-direction: column;
    margin-top: 1.25em;
    background-color: white;
    width: 100%;
    padding: 1em 0;
    border-radius: 5px;
  }
  .dashboard-datepicker {
    margin-top: 0.75em;
  }
  .map-container {
    margin-top: 0;
    max-height: 300px;
  }
  .stats-card-container {
    display: none !important;
  }
  .job-feed-container {
    margin-top: 0;
  }
  .job-feed-card {
    border-radius: 5px !important;
  }
  .main-table {
    margin-top: 1em !important;
    border-radius: 5px !important;
  }
  .divider {
    margin-top: 1em;
  }
  .timeline-details-name {
    font-size: 0.85em;
  }
  .timeline-details-check-text {
    font-size: 0.85em;
  }
  .stats-card:last-child {
    margin-right: 0 !important;
  }
  .job-feed-header-title {
    font-size: 1em;
  }
  .job-feed-header-icon {
    height: 21px;
  }
  .work-status-table-title {
    font-size: 0.85em;
  }
  .map-filters-container {
    width: 100%;
    margin-top: 0 !important;
    margin-bottom: 1em !important;
  }
  .map-filters-container > .ant-card-body {
    flex-direction: column;
    align-items: flex-start !important;
  }
  .map-filters-container > .ant-card-body > .filter-label {
    font-weight: bold;
  }
  .filter-options-container {
    flex-wrap: wrap;
  }
  .checkbox-option {
    margin: 0 !important;
    margin-top: 10px !important;
    min-width: 50% !important;
  }
}

.map_pin_job-tooltip_container {
  width: 244px;
  height: 120px;
  padding: 0;
  margin: 0 0.5em;
}

.map_pin_job-tooltip_header,
.map_pin_job_duration_container,
.map_pin_job_user_info_container {
  display: flex;
  align-items: center;
}

.map_pin_job_user_info_container {
  align-items: flex-start;
}

.map_pin_job_user_info {
  display: flex;
  flex-direction: column;
  margin-left: 1em;
}

.map_pin_job_duration_container {
  margin: 0.5em 0;
}

.map_pin_job-tooltip_header {
  justify-content: space-between;
}

.map_pin_job-tooltip_header_title,
.map_pin_job_time,
.map_pin_job_duration_title,
.map_pin_job_duration_text,
.map_pin_job_user_info_name,
.map_pin_job_user_info_addr,
.map_pin_job_user_info_tag {
  padding: 0;
  margin: 0;
  font-size: 1.25em;
}

.map_pin_job_duration_title,
.map_pin_job_duration_text,
.map_pin_job_user_info_addr,
.map_pin_job_user_info_tag {
  font-size: 1.15em;
  color: #2b2b2b;
  color: var(--color4);
}

.map_pin_job-tooltip_header_title,
.map_pin_job_user_info_name {
  font-weight: bold;
}

.dot {
  width: 8px;
  height: 8px;
  background-color: #828282;
  background-color: var(--color5);
  border-radius: 50%;
  margin: 0 0.5em;
}

.truncate {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.date-picker-dashboard > .ant-picker-input > input {
  text-align: center;
  width: 75px;
}

.job-feed-card > .ant-card-body {
  padding: 0 !important;
}

@media (max-width: 576px) {
  .header-container {
    padding: 1em;
    margin: 0;
    margin-top: 1em;
    background-color: white;
    border-radius: 5px;
  }
  .header-container > .header-title {
    padding: 0;
    margin: 0;
  }
}

.jobs-page .page-header {
    display: block;
    padding-bottom: 0;
}
.jobs-page .page-header .ant-col {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1 1;
}

.job-detail-drawer .drawer-header {
    padding-bottom: 30px;
}
.job-detail-drawer .drawer-body, .job-detail-drawer .drawer-footer {
    border-top: 1px solid #F2F2F2;
    padding: 15px 5px;
}
.job-detail-drawer .ant-row {
    padding: 15px 0;
}
.job-detail-drawer label {
    display: block;
    font-weight: 500;
    font-size: 16px;
    color: #2B2B2B;
}
.job-detail-drawer .drawer-body span, .job-detail-drawer .drawer-footer span {
    display: block;
    margin-top: 10px;
    font-size: 16px;
    color: #828282;
}
.job-detail-drawer .badge {
    border-radius: 5px;
    padding: 8px 16px;
    color: white;
    margin-right: 15px;
}
.job-detail-drawer .drawer-header .header-actions {
    display: flex;
    align-items: center;
}
.job-detail-drawer .badge.completed {
    background: #BDBDBD;
}
.job-detail-drawer .badge.cancelled {
    background: #DF5E67;
}
.job-detail-drawer .badge.pending_schedule {
    background: #F1B13B;
}
.job-detail-drawer .badge.in_progress {
    background: #6ABB98;
}
.job-detail-drawer .badge.scheduled {
    background: #34A6BD;
}
.job-detail-drawer .badge.repeating {
    background: #6ABB98;
}
.job-detail-drawer .badge.no-repeating {
    background: #DF5E67;
}
.limit-title {
    font-size: 0.75em;
    color: #ccc;
    font-style: italic;
}
.job-freq-field-container {
    display: flex;
    align-items: center;
}

.job-freq-field-container > .ant-col > p {
    padding: 0;
    margin: 0;
}
.vendor-profile-page .vendor-info {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-bottom: 50px;
}

.vendor-profile-page .vendor-info .vendor-avatar {
    padding: 0 15px;
    display: flex;
    align-items: center;
}
.vendor-profile-page .vendor-avatar-info {
    padding-left: 15px;
}
.vendor-profile-page .vendor-avatar-info .vendor-name {
    font-weight: 500;
    font-size: 18px;
    color: #2B2B2B;
    margin-bottom: 5px;
}
.vendor-profile-page .vendor-avatar-info .vendor-id {
    color: #4F4F4F;
}

.vendor-profile-page .vendor-info-block {
    padding: 0 15px;
}
.vendor-profile-page .vendor-info-block label {
    display: block;
    font-weight: 500;
    font-size: 16px;
    color: #2B2B2B;
}
.vendor-profile-page .vendor-info-block span {
    display: block;
    font-size: 16px;
    color: #828282;
    margin-top: 10px;
}

.page-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  box-shadow: 2px 4px 9px rgba(199, 199, 199, 0.5);
  background: white;
  padding: 16px 24px;
  z-index: 1;
}

.page-title {
  display: flex;
  align-items: baseline;
  min-width: 200px;
}

.page-title span {
  margin-right: 10px;
}

.page-title h4 {
  font-size: 20px;
  margin: 0;
  color: #2b2b2b;
}

.schedule-page .page-title {
  display: block;
}

.schedule-page .page-content {
  position: relative;
  padding: 0;
  margin-top: 0;
}

.vendor-list {
  position: absolute;
  bottom: 0;
  top: 0;
  padding-right: 12px;
  transition: width 0.35s;
}

.vendor-list.fixed {
  position: fixed;
  top: 0;
}

.vendor-list.collapsed {
  width: 27px;
}

.vendor-list.expanded {
  width: 262px;
}

.vendor-list-container {
  width: 100%;
  background: white;
  overflow: hidden;
  height: 100%;
  box-shadow: 2px 4px 9px rgba(199, 199, 199, 0.5);
}

.vendor-list-content {
  width: 250px;
  padding: 24px 15px;
}

.vendor-list .btn-collapse {
  position: absolute;
  top: 24px;
  right: 0;
  border-radius: 50%;
  box-shadow: 2px 4px 9px rgba(199, 199, 199, 0.5);
  border: none;
  width: 24px;
  height: 24px;
  background: white;
}

.vendor-list > .ant-btn {
  background-color: white !important;
}

.vendor-list .btn-collapse span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.vendor-list .btn-collapse svg {
  width: 10px;
  height: 10px;
  transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  transition: transform 0.35s, -webkit-transform 0.35s;
}

.vendor-list.collapsed .btn-collapse svg {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.vendor-list .list-header {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.vendor-list .list-header span {
  cursor: pointer;
  margin-right: 15px;
}

.vendor-list .list-header h3 {
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 0;
}

.vendor-list .vendor-info {
  padding-top: 15px;
  border-top: 1px solid #f2f2f2;
}

.vendor-list .vendor-info p {
  margin: 0;
  font-size: 13px;
  color: #828282;
}

.vendor-list .vendor-info p:first-child {
  color: #2b2b2b;
  font-weight: 500;
  font-size: 14px;
}

.vendor-list .list-content {
  margin-top: 16px;
}

.vendor-list .list-content .list-content-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
}

.vendor-list .list-content .list-content-header h4 {
  font-size: 16px;
  margin: 0;
}

.vendor-list .list-content .list-content-header button {
  background: #2d9cdb1a;
  color: #2d9cdb;
  border: none;
  border-radius: 4px;
}

.vendor-list .list-content .list-content-body {
  padding: 8px 0;
  border-top: 1px solid #f2f2f2;
}

.vendor-list .list-content .list-content-body .vendor-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  cursor: pointer;
}

.vendor-item:hover {
  color: #1890ff;
}

.vendor-item .ant-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background: #f1b13b;
  color: white;
  border-radius: 50%;
}

.schedule-content {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  min-height: calc(100vh + 1px);
  transition: margin 0.35s;
}

.vendor-list.expanded + .schedule-content {
  margin-left: 262px;
}

.vendor-list.expanded + .schedule-content .worker-card {
  width: 24%;
  margin-left: 20px;
  min-width: 265px;
}

/* .vendor-list.expanded+.schedule-content .worker-card:nth-child(3n + 1) {
    margin-left: 0;
} */

.vendor-list.collapsed + .schedule-content {
  margin-left: 27px;
}

.vendor-list.collapsed + .schedule-content .worker-card {
  width: 24%;
  margin-right: 2%;
  min-width: 265px;
}

.vendor-list.collapsed + .schedule-content .worker-card:nth-child(4n + 1) {
  margin-left: 0;
}

.schedule-content .main-content {
  flex: 1 1;
  padding-top: 24px;
  padding-left: 8px;
  padding-right: 20px;
}

.schedule-content-header-top {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.schedule-content-header-top h3 {
  margin-bottom: 0;
  font-size: 18px;
  color: #2b2b2b;
}

.schedule-content-header-top h4 {
  margin-bottom: 0;
  /* margin-left: 5px; */
  font-size: 16px;
  color: #4f4f4f;
}

.schedule-content-header-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.schedule-content-header-bottom p {
  margin: 0;
  font-size: 13px;
  color: #828282;
}

.schedule-content-header-pagination {
  display: flex;
  align-items: center;
}

.schedule-content-header-pagination span:first-child {
  font-size: 12px;
  color: #828282;
}

.schedule-content-header-pagination span + span {
  margin-left: 10px;
  cursor: pointer;
}

.schedule-content-body {
  display: flex;
  flex-wrap: wrap;
  padding: 16px 0;
}

.loading-overlay {
  padding: 40px 15px;
  text-align: center;
}

.loading-overlay svg {
  width: 32px;
  height: 32px;
}

.job-card {
  border: 1px solid #34a6bd;
  box-shadow: 0 4px 4px rgba(235, 235, 235, 0.5);
  border-radius: 5px;
  cursor: pointer;
  transition: height 0.35s;
  height: 92px;
  overflow-y: hidden;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background: white;
}

.job-card--with-timer-vendor-list {
  min-height: 144px;
}

.job-card--with-timer-worker-list {
  min-height: 94px;
}

.job-card.opened .job-title {
  color: #34a6bd;
}

.job-card-content {
  padding: 10px 8px;
}

.job-card .job-summary {
  padding-bottom: 16px;
}

.job-card .job-time,
.job-card .job-location {
  padding: 8px 0;
  border-top: 1px solid #e0e0e0;
}

.job-card .job-notes {
  padding: 2px 0;
  border-top: 1px solid #e0e0e0;
}

.job-location {
  padding-bottom: 0px !important;
}

.job-location-unit-postal {
  display: flex;
  justify-content: space-between;
}

.job-location-postal-code {
  text-align: right;
}

.job-counter {
  padding: 8px 0;
  border-top: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.job-counter-text,
.job-counter-title {
  padding: 0;
  margin: 0;
  text-align: center;
  font-weight: bold;
  font-size: 1em;
  color: #828282;
}

.job-counter-title {
  margin-right: 0.5em;
  font-weight: normal;
  letter-spacing: 1px;
  color: #fff;
  text-transform: uppercase;
  border-radius: 3px;
  font-size: 0.65em;
  padding: 0.25em 1em;
  background-color: #34a6bd;
}

.job-counter-title--overtime {
  background-color: #e20101c4;
  color: rgb(255, 255, 255);
}

.job-counter-title--completed {
  background-color: #6abb98;
  color: rgb(255, 255, 255);
}

.job-card .job-notes {
  margin-bottom: 0;
}

.job-card .ant-btn {
  margin-top: 10px;
  border: none;
  border-radius: 4px;
}

.job-card .ant-btn:hover {
  opacity: 0.8;
}

.job-card .ant-btn-primary {
  background: #6abb98;
}

.job-card .ant-btn-default {
  background: #2d9cdb;
  color: white;
}

.job-summary-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.job-summary-row + .job-summary-row {
  padding-top: 10px;
}

.job-summary-row span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.job-summary-row .job-title {
  font-weight: 500;
  font-size: 14px;
  color: #2b2b2b;
  max-width: 140px;
}

.job-summary-row .job-id {
  color: #bdbdbd;
  font-size: 10px;
  max-width: 80px;
}

.job-summary-row .job-desc {
  font-size: 12px;
  color: #828282;
  max-width: 180px;
}

.job-summary-row .job-type {
  font-size: 10px;
  color: #4f4f4f;
}

.job-summary-row .job-company {
  font-size: 11px;
  color: #828282;
  max-width: 160px;
}

.job-card .job-summary-row .job-duration {
  font-size: 11px;
  color: #4f4f4f;
  max-width: 60px;
}

.job-time .preferred-time {
  padding-bottom: 0px;
  text-align: center;
}

.job-time .preferred-time span:first-child {
  display: block;
  font-size: 11px;
  color: #828282;
  padding-bottom: 3px;
}

.job-time .preferred-time span:last-child {
  display: block;
  font-weight: 500;
  font-size: 14px;
  color: #2b2b2b;
}

.job-time .job-time-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 5px;
}

.job-time .job-time-row label {
  font-size: 13px;
  color: #828282;
}

.job-time .job-time-row span {
  font-size: 13px;
  font-weight: 500;
  color: #2b2b2b;
}

.job-location-row label {
  display: block;
  font-size: 11px;
  color: #828282;
  margin-bottom: 0;
}

.job-location-row span {
  display: block;
  font-size: 13px;
  font-weight: 500;
  color: #2b2b2b;
}

.job-location-row {
  margin-bottom: 7.5px;
}

.job-notes {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.job-notes .job-notes-viewmore {
  display: flex;
  justify-content: space-between;
}

.job-notes .job-notes-row label {
  display: block;
  font-size: 11px;
  color: #828282;
  margin-bottom: 4px;
}

.job-notes .ant-checkbox-wrapper {
  margin-top: 8px;
}

.job-notes .job-notes-row span {
  display: block;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  /*color: #2B2B2B;*/
}

.job-notes .ant-btn-link {
  padding: 0 10px;
  margin: 0;
  height: 18px;
  border: none;
  font-weight: 500;
  font-size: 12px;
  color: #2d9cdb;
}

.worker-card {
  background: #ffffff;
  box-shadow: 2px 4px 9px rgba(199, 199, 199, 0.5);
  border-radius: 4px;
  margin: 16px 0;
  padding: 16px;
  max-width: 250px;
}

.worker-card-header {
  display: flex;
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 15px;
}

.worker-avatar {
  margin-right: 10px;
}

.worker-avatar > span {
  width: 40px;
  height: 40px;
  line-height: 40px;
}

.worker-avatar > img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}

.worker-info {
  flex: 1 1;
  max-width: calc(100% - 50px);
}

.worker-info span {
  font-size: 12px;
  color: #828282;
}

.worker-info .worker-name {
  display: flex;
  align-items: center;
}

.worker-info .worker-name span:first-child {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 60px);
  font-weight: 500;
  font-size: 14px;
  color: #000000;
}

.worker-card .job-assigned {
  padding: 15px 0;
}

.job-assigned .job-assigned-row {
  display: flex;
  justify-content: space-between;
}

.job-assigned .job-assigned-row label {
  font-size: 12px;
  color: #828282;
}

.job-assigned .job-assigned-row span {
  font-size: 12px;
  color: #34a6bd;
}

.job-assigned .job-assigned-row:first-child {
  padding-bottom: 10px;
}

.worker-schedule {
  padding-top: 15px;
}

.worker-schedule--disabled {
  border: 1px solid #828282;
  padding: 1em;
  border-radius: 0.25rem;
  background-color: #fafafa;
  cursor: not-allowed;
}

.worker-schedule .schedule-row {
  position: relative;
  padding-top: 9px;
}

.schedule-row > label {
  position: absolute;
  top: 0;
  font-size: 12px;
  color: #828282;
}

.schedule-row .schedule-row-content {
  display: flex;
  flex-direction: column;
  min-height: 40px;
  padding: 8px 0;
  border-top: 1px solid #f2f2f2;
}

.schedule-row .job-card,
.job-card.dragging {
  border-color: rgb(106, 187, 152);
  height: 55px;
}

.schedule-row .job-card-content,
.job-card.dragging .job-card-content {
  padding: 8px;
}

.schedule-row .job-summary,
.job-card.dragging .job-summary {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px !important;
}

.schedule-row .job-summary-row,
.job-card.dragging .job-summary-row {
  display: none;
}

.schedule-row .job-time .job-time-row label {
  font-size: 11px;
}

.job-card .job-main-info {
  display: none;
  width: calc(100% - 60px);
}

.job-card .job-main-info .job-title {
  font-weight: 600;
  font-size: 13px;
  color: #2b2b2b;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.job-card .job-main-info .job-desc {
  font-size: 11px;
  color: #bdbdbd;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.job-card .job-summary > .job-duration {
  display: none;
  font-size: 10px;
  color: #828282;
  text-align: right;
}

.schedule-row .job-card .job-main-info,
.job-card.dragging .job-main-info,
.schedule-row .job-summary > .job-duration,
.job-card.dragging .job-summary > .job-duration {
  display: block;
}

.schedule-row .job-card .ant-btn {
  font-size: 11px;
}

.drop-preview {
  background: rgba(106, 187, 152, 0.1);
  height: 55px;
  border: 1px solid #6abb98;
  border-radius: 4px;
}

.job-cancel-modal {
  text-align: center;
}

.job-cancel-modal p:first-child {
  color: #2d9cdb;
  font-weight: 500;
  font-size: 18px;
}

.job-cancel-modal p:nth-child(2) {
  font-size: 14px;
  color: #828282;
}

.job-checked-in {
  background-color: #b2dfdb;
}

.job-not-checked-in {
  background-color: #fff;
}

.job-not-checked-in > .job-card-content > .job-summary > .job-summary-row {
  padding-top: 3px;
}

.job-checked-out {
  background-color: #f5f5f5;
}

.job-status-cancelled {
  background-color: #e57373;
  border: 1px solid #d32f2f;
}

.job-time-difference {
  display: flex;
  justify-content: space-between;
  font-size: 11px;
}

.job-time-difference-time {
  font-size: 10px;
}

.job-checked-in .job-title,
.job-checked-in .job-id,
.job-checked-in .job-desc,
.job-checked-in .job-type,
.job-checked-in .job-company,
.job-checked-in .job-duration,
.job-checked-in .preferred-time > span,
.job-checked-in .job-time-row > label,
.job-checked-in .job-time-row > span,
.job-checked-in .job-location-row > label,
.job-checked-in .job-location-row > span,
.job-checked-in .job-notes-viewmore > label,
.job-checked-in .job-notes-row > span,
.job-checked-in .job-notes-viewmore > .ant-btn,
.job-checked-in .job-card-content > .job-counter > .job-counter-text,
.job-checked-in .job-card-content > .job-time-difference,
.job-checked-in .ant-checkbox-wrapper > span {
  color: #00897b !important;
}

.job-checked-in .job-desc,
.job-checked-in .job-card-content > .job-time-difference > p:first-child,
.job-checked-in .preferred-time > span:first-child,
.job-checked-in .job-time-row > label,
.job-checked-in .job-notes-viewmore > .ant-btn,
.job-checked-in .job-location-row > label {
  color: #25ada0 !important;
}

.job-status-cancelled .job-title,
.job-status-cancelled .job-id,
.job-status-cancelled .job-desc,
.job-status-cancelled .job-type,
.job-status-cancelled .job-company,
.job-status-cancelled .job-duration,
.job-status-cancelled .preferred-time > span,
.job-status-cancelled .job-time-row > label,
.job-status-cancelled .job-time-row > span,
.job-status-cancelled .job-location-row > label,
.job-status-cancelled .job-location-row > span,
.job-status-cancelled .job-notes-viewmore > label,
.job-status-cancelled .job-notes-row > span,
.job-status-cancelled .job-notes-viewmore > .ant-btn,
.job-status-cancelled .job-card-content > .job-counter > .job-counter-text,
.job-status-cancelled .job-card-content > .job-time-difference,
.job-status-cancelled .ant-checkbox-wrapper > span {
  color: #fff !important;
}

.hightlighter-1 {
  border-left: 4px solid #408dbf !important;
}

.hightlighter-2 {
  border-left: 4px solid #5e862d !important;
}

.schedule-content-header-top > .header-dash {
  margin-left: 10px;
  margin-right: 10px;
}

.route-name {
  display: none;
}

@media screen and (max-width: 576px) {
  .page-header {
    padding: 0;
  }

  .page-title {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 200px;
    width: 100%;
    border-bottom: 2px solid #ececec;
    position: fixed;
    top: 64px;
    background-color: white;
  }

  .header-drawer-icon {
    flex: 1 1;
  }

  .route-name {
    display: flex;
    justify-content: center;
    text-transform: capitalize;
    font-weight: bold;
    margin-bottom: 0;
    line-height: 24px;
    flex: 3 1;
  }

  .main-header {
    flex: 1 1;
    max-width: 55px;
  }

  .page-title h4 {
    /* font-size: 18px;
        text-align: center;
        padding: 0.5em 0;
        border-bottom: 2px solid #ececec; */
    display: none;
  }

  .page-date-selector {
    width: 70%;
    margin: 1em auto;
  }

  .page-date-selector > .ant-col > .ant-picker > .ant-picker-input > input {
    text-align: center;
  }

  .page-btn > .anticon {
    margin-right: 0;
  }

  .schedule-content-body {
    flex-wrap: unset;
    overflow-x: scroll;
  }

  .vendor-list.fixed {
    position: fixed;
    top: 126px;
  }

  .vendor-list {
    z-index: 1;
  }

  .vendor-list.expanded + .schedule-content {
    margin-left: 0;
  }

  .vendor-list.expanded + .schedule-content .worker-card {
    width: 24%;
    margin-left: 20px;
    min-width: 265px;
  }

  .vendor-list.expanded + .schedule-content .worker-card:nth-child(3n + 1) {
    margin-left: 0;
  }

  .vendor-list.collapsed + .schedule-content {
    margin-left: 27px;
  }

  .vendor-list.collapsed + .schedule-content .worker-card {
    width: 24%;
    margin-right: 2%;
    min-width: 265px;
  }

  .vendor-list.collapsed + .schedule-content .worker-card:nth-child(4n + 1) {
    margin-left: 0;
  }

  .schedule-content .main-content {
    flex: 1 1;
    padding-top: 22px;
    padding-left: 0;
    padding-right: 20px;
  }

  .schedule-content-header {
    padding-left: 16px;
  }

  .schedule-content-header-top {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0;
  }

  .schedule-content-header-top > h3 {
    font-size: 1.25em;
    font-weight: bold;
  }

  .schedule-content-header-top > h4 {
    font-size: 1em;
    font-weight: 700;
    color: #4f4f4f;
  }

  .schedule-content-header-top > .header-dash {
    display: none;
  }

  .schedule-page > .page-content {
    margin-top: 126px;
    z-index: 0;
  }
}

.clients-page .page-header {
  display: block;
  padding-bottom: 1rem;
}
.clients-page .page-header .ant-col {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 1;
}

body #root .ant-tabs-tab {
  font-size: 16px;
  margin: 0;
  padding: 15px;
}
body #root .ant-tabs-tab-active {
  color: #2d9cdb;
  font-weight: 500;
  border-bottom: 3px solid #2d9cdb;
}

.job-profile-page .job-info {
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
}
.job-profile-page .job-title .job-info-block {
    padding: 0;
}
.job-profile-page .job-other-info {
    display: flex;
}
.job-profile-page .job-info-block {
    padding: 0 15px;
}
.job-profile-page .job-info-block label {
    display: block;
    font-weight: 500;
    font-size: 16px;
    color: #2B2B2B;
}
.job-profile-page .job-info-block span {
    display: block;
    font-size: 16px;
    color: #828282;
    margin-top: 10px;
}

.job-info-top {
    border-bottom: 1px solid #F2F2F2;
}
.job-detail-block {
    padding: 15px;
}
.job-detail-block label {
    display: block;
    font-weight: 500;
    font-size: 16px;
    color: #2B2B2B;
    margin-bottom: 10px;
}
.job-detail-block span {
    display: block;
    color: #828282;
    font-size: 16px;
}

.page-sub-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}
.page-sub-header h4 {
    margin: 0;
    font-size: 20px;
}

.assign-worker-modal .ant-modal-header {
    border: none;
}
.assign-worker-modal .ant-modal-footer {
    border: none;
}
.assign-worker-modal .date-range  {
    padding-bottom: 30px;
    border-bottom: 1px solid #E0E0E0;
}
.assign-worker-modal label {
    font-weight: 500;
    font-size: 16px;
    color: #2B2B2B;
}
.assign-worker-modal .form-row {
    display: flex;
    align-items: center;
}
.assign-worker-modal .form-row > span {
    margin: 0 24px;
    font-size: 16px;
    color: black;
}
.assign-worker-modal .assign-worker {
    padding-top: 30px;
}
.assign-worker-modal .form-group + .form-group {
    margin-top: 24px;
}
td, th {
    vertical-align:top;
}
.ant-list-item-meta-description
{
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', "tnum", "tnum";
    height: 24px;
    line-height: 24px;
}
ul.ant-list-items > li:first-child{
    padding-top: 0;
}
.ant-input-number{
    min-width: 100%;
}

.product-create-field-title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1em;
}

.product-create-field-title {
    margin: 0;
    padding: 0;
    color: #2B2B2B;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
}

.product-create-field-title-btn {
    background: transparent;
    outline: none;
    border: none;
    color: #1890ff;
    cursor: pointer;
}
td, th {
    vertical-align:top;
}
.ant-list-item-meta-description
{
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', "tnum", "tnum";
    height: 24px;
    line-height: 24px;
}
ul.ant-list-items > li:first-child{
    padding-top: 0;
}
.ant-input-number{
    min-width: 100%;
}
.avatar-uploader .ant-upload-select {
  width: 100px;
  height: 100px;
  margin: 0;
  border: none;
  background: transparent;
  cursor: default;
}
.avatar-uploader .ant-upload-select > span.ant-upload {
  padding: 0;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}

.cross {
  line-height: 0;
  font-size: 3em;
}

.error .ant-upload-select-picture-card {
  border: 1px dashed red;
}

.image-alert-message {
  color: #ff4d4f;
  font-size: 14px;
  line-height: 1.5715;
  margin-top: -8px;
}

:root {
  --color1: #2f80ed;
  --color2: #2d9cdb;
  --color3: #6abb98;
  --color4: #2b2b2b;
  --color5: #828282;
  --color6: #f1b13b;
  --color7: #df5e67;
}

.transaction-container {
  width: 100%;
  font-family: 'Roboto', sans-serif;
}

.transaction-content-container {
  margin: 2.5em;
}

.font-bold {
  font-weight: bold;
  letter-spacing: 1px;
}

.tran-type-debit,
.tran-status-success {
  color: #6abb98;
  color: var(--color3);
}

.tran-type-credit {
  color: #df5e67;
  color: var(--color7);
}

.link:hover {
  text-decoration: underline;
}

.table-column-title {
  color: #2f80ed;
  color: var(--color1);
  font-weight: normal;
}

.link-disabled,
.link-disabled:hover {
  color: #828282;
  color: var(--color5);
  cursor: not-allowed;
}

.hide-column {
  display: none;
}

.order-item-table-heading-container {
  padding: 1em 0 0.5em 0;
}

.order-item-table-heading {
  padding: 0;
  margin: 0;
  font-weight: bold;
  font-size: 1.25em;
}

.order-item-table-text {
  padding: 0;
  margin: 0;
  margin-top: 1em;
  font-size: 0.875em;
}

.order-item-table-footer-container {
  display: flex;
}

.order-item-table-footer-text {
  padding: 0;
  margin: 0;
  font-weight: bold;
  font-size: 1.15em;
}

.oi-table-footer-heading {
  flex: 4.7 1;
  text-align: right;
  padding-right: 2em;
}

.oi-table-footer-text {
  flex: 1 1;
}

.color-yellow {
  color: #f1b13b;
  color: var(--color6);
}

.color-green {
  color: #6abb98;
  color: var(--color3);
}

.color-red {
  color: #df5e67;
  color: var(--color7);
}

.color-gray {
  color: #828282;
  color: var(--color5);
}

.avatar-uploader .ant-upload-select {
  width: 100px;
  height: 100px;
  margin: 0;
  border: none;
  background: transparent;
  cursor: default;
}
.avatar-uploader .ant-upload-select > span.ant-upload {
  padding: 0;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}

.cross {
  line-height: 0;
  font-size: 3em;
}
/* .ant-upload .ant-upload-select-picture-card{
  width: 60px !important;
    height: 60px !important;
} */

/* 
.ant-upload-list .ant-upload-list-picture-card 
.ant-upload .ant-upload-select .ant-upload-select-picture-card{
} */
.ant-upload-picture-card-wrapper
  .ant-upload-list
  .ant-upload-list-picture-card-container {
  width: 80px;
  height: 80px;
}
.error .ant-upload-select-picture-card {
  border: 1px dashed red;
}
.image-alert-message {
  color: #ff4d4f;
  font-size: 14px;
  line-height: 1.5715;
  margin-top: -8px;
}

/* @media only screen and (max-width: 600px) {
  .ant-upload.ant-upload-select-picture-card{
    width: 100% !important;
  }
} */

td,
th {
  vertical-align: top;
}
.ant-list-item-meta-description {
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', 'tnum', "tnum";
  height: 24px;
  line-height: 24px;
}
ul.ant-list-items > li:first-child {
  padding-top: 0;
}
.ant-input-number {
  min-width: 100%;
}

td, th {
    vertical-align:top;
}
.ant-list-item-meta-description
{
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', "tnum", "tnum";
    height: 24px;
    line-height: 24px;
}
ul.ant-list-items > li:first-child{
    padding-top: 0;
}
.ant-input-number{
    min-width: 100%;
}
.product-create-field-title-container {
    display: flex;
    margin-top: -2px;
    
}
.page-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  box-shadow: 2px 4px 9px rgba(199, 199, 199, 0.5);
  background: white;
  padding: 16px 24px;
  z-index: 1;
}

.page-title {
  display: flex;
  align-items: baseline;
  min-width: 200px;
}

.page-title span {
  margin-right: 10px;
}

.page-title h4 {
  font-size: 20px;
  margin: 0;
  color: #2b2b2b;
}

.schedule-page .page-title {
  display: block;
}

.schedule-page .page-content {
  position: relative;
  padding: 0;
  margin-top: 0;
}

/*
.
.   Vendor list
.
*/

.vendor-list {
  position: absolute;
  bottom: 0;
  top: 0;
  padding-right: 12px;
  transition: width 0.35s;
}

.vendor-list.fixed {
  position: fixed;
  top: 0;
}

.vendor-list.collapsed {
  width: 27px;
}

.vendor-list.expanded {
  width: 262px;
}
.vendor-list.unassigned-jobs {
  width: 232px;
}

.vendor-list-container {
  width: 100%;
  background: white;
  overflow: hidden;
  height: 100%;
  box-shadow: 2px 4px 9px rgba(199, 199, 199, 0.5);
}

.vendor-list-content {
  width: 250px;
  padding: 24px 15px;
}

.vendor-list .btn-collapse {
  position: absolute;
  top: 24px;
  right: 0;
  border-radius: 50%;
  box-shadow: 2px 4px 9px rgba(199, 199, 199, 0.5);
  border: none;
  width: 24px;
  height: 24px;
  background: white;
}

.vendor-list > .ant-btn {
  background-color: white !important;
}

.vendor-list .btn-collapse span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.vendor-list .btn-collapse svg {
  width: 10px;
  height: 10px;
  transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  transition: transform 0.35s, -webkit-transform 0.35s;
}

.vendor-list.collapsed .btn-collapse svg {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.vendor-list .list-header {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.vendor-list .list-header span {
  cursor: pointer;
  margin-right: 15px;
}

.vendor-list .list-header h3 {
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 0;
}

.vendor-list .vendor-info {
  padding-top: 15px;
  border-top: 1px solid #f2f2f2;
}

.vendor-list .vendor-info p {
  margin: 0;
  font-size: 13px;
  color: #828282;
}

.vendor-list .vendor-info p:first-child {
  color: #2b2b2b;
  font-weight: 500;
  font-size: 14px;
}

.vendor-list .list-content {
  margin-top: 16px;
}

.vendor-list .list-content .list-content-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
}

.vendor-list .list-content .list-content-header h4 {
  font-size: 16px;
  margin: 0;
}

.vendor-list .list-content .list-content-header button {
  background: #2d9cdb1a;
  color: #2d9cdb;
  border: none;
  border-radius: 4px;
}

.vendor-list .list-content .list-content-body {
  padding: 8px 0;
  border-top: 1px solid #f2f2f2;
}

.vendor-list .list-content .list-content-body .vendor-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  cursor: pointer;
}

.vendor-item:hover {
  color: #1890ff;
}

.vendor-item .ant-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background: #f1b13b;
  color: white;
  border-radius: 50%;
}

.schedule-content {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  min-height: calc(100vh + 1px);
  transition: margin 0.35s;
}

.vendor-list.expanded ~ .schedule-content {
  margin-left: 262px;
}

.vendor-list.expanded ~ .schedule-content .worker-card {
  width: 24%;
  margin-left: 20px;
  min-width: 265px;
}

/* .vendor-list.expanded+.schedule-content .worker-card:nth-child(3n + 1) {
    margin-left: 0;
} */

.vendor-list.collapsed ~ .schedule-content {
  margin-left: 27px;
}

.vendor-list.collapsed ~ .schedule-content .worker-card {
  width: 24%;
  margin-right: 2%;
  min-width: 265px;
}

.vendor-list.collapsed ~ .schedule-content .worker-card:nth-child(4n + 1) {
  margin-left: 0;
}

/*
.
.   Worker card container
.
*/

.worker-container-border .job-card {
  border: 1px solid #c16f6f !important;
  border-radius: 0.25rem;
  padding: 1px 5px;
}

/*
.
.   Schedule
.
*/

.schedule-content .main-content {
  flex: 1 1;
  padding-top: 24px;
  padding-left: 8px;
  padding-right: 20px;
}

.schedule-content-header-top {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.schedule-content-header-top h3 {
  margin-bottom: 0;
  font-size: 18px;
  color: #2b2b2b;
}

.schedule-content-header-top h4 {
  margin-bottom: 0;
  /* margin-left: 5px; */
  font-size: 16px;
  color: #4f4f4f;
}

.schedule-content-header-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.schedule-content-header-bottom p {
  margin: 0;
  font-size: 13px;
  color: #828282;
}

.schedule-content-header-pagination {
  display: flex;
  align-items: center;
}

.schedule-content-header-pagination span:first-child {
  font-size: 12px;
  color: #828282;
}

.schedule-content-header-pagination span + span {
  margin-left: 10px;
  cursor: pointer;
}

.schedule-content-body {
  display: flex;
  flex-wrap: wrap;
  padding: 16px 0;
}

.loading-overlay {
  padding: 40px 15px;
  text-align: center;
}

.loading-overlay svg {
  width: 32px;
  height: 32px;
}

.job-card {
  border: 1px solid #34a6bd;
  box-shadow: 0 4px 4px rgba(235, 235, 235, 0.5);
  border-radius: 5px;
  cursor: pointer;
  transition: height 0.35s;
  height: 92px;
  overflow-y: hidden;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background: white;
}

.job-card--with-timer-vendor-list {
  min-height: 144px;
}

.job-card--with-timer-worker-list {
  min-height: 94px;
}

.job-card.opened .job-title {
  color: #34a6bd;
}

.job-card-content {
  padding: 10px 8px;
}

.job-card .job-summary {
  padding-bottom: 16px;
}

.job-card .job-time,
.job-card .job-location,
.job-card .job-notes {
  padding: 8px 0;
  border-top: 1px solid #e0e0e0;
}

.job-location {
  padding-bottom: 0px !important;
}

.job-location-unit-postal {
  display: flex;
  justify-content: space-between;
}

.job-location-postal-code {
  text-align: right;
}

.job-counter {
  padding: 8px 0;
  border-top: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.job-counter-text,
.job-counter-title {
  padding: 0;
  margin: 0;
  text-align: center;
  font-weight: bold;
  font-size: 1em;
  color: #828282;
}

.job-counter-title {
  margin-right: 0.5em;
  font-weight: normal;
  letter-spacing: 1px;
  color: #fff;
  text-transform: uppercase;
  border-radius: 3px;
  font-size: 0.65em;
  padding: 0.25em 1em;
  background-color: #34a6bd;
}

.job-counter-title--overtime {
  background-color: #e20101c4;
  color: rgb(255, 255, 255);
}

.job-counter-title--completed {
  background-color: #6abb98;
  color: rgb(255, 255, 255);
}

.job-card .job-notes {
  margin-bottom: 0;
}

.job-card .ant-btn {
  margin-top: 10px;
  border: none;
  border-radius: 4px;
}

.job-card .ant-btn:hover {
  opacity: 0.8;
}

.job-card .ant-btn-primary {
  background: #6abb98;
}

.job-card .ant-btn-default {
  background: #2d9cdb;
  color: white;
}

.job-summary-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.job-summary-row + .job-summary-row {
  padding-top: 10px;
}

.job-summary-row span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.job-summary-row .job-title {
  font-weight: 500;
  font-size: 14px;
  color: #2b2b2b;
  max-width: 140px;
}

.job-summary-row .job-id {
  color: #bdbdbd;
  font-size: 10px;
  max-width: 80px;
}

.job-summary-row .job-desc {
  font-size: 12px;
  color: #828282;
  max-width: 180px;
}

.job-summary-row .job-resume-title {
  font-size: 12px;
  color: #828282;
  max-width: 180px;
}

.job-summary-row .job-resume {
  font-size: 10px;
  color: #4f4f4f;
}

.job-summary-row .job-type {
  font-size: 10px;
  color: #4f4f4f;
}

.job-summary-row .job-company {
  font-size: 11px;
  color: #828282;
  max-width: 160px;
}

.job-card .job-summary-row .job-duration {
  font-size: 11px;
  color: #4f4f4f;
  max-width: 60px;
}

.job-time .preferred-time {
  padding-bottom: 0px;
  text-align: center;
}

.job-time .preferred-time span:first-child {
  display: block;
  font-size: 11px;
  color: #828282;
  padding-bottom: 3px;
}

.job-time .preferred-time span:last-child {
  display: block;
  font-weight: 500;
  font-size: 14px;
  color: #2b2b2b;
}

.job-time .job-time-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 5px;
}

.job-time .job-time-row label {
  font-size: 13px;
  color: #828282;
}

.job-time .job-time-row span {
  font-size: 13px;
  font-weight: 500;
  color: #2b2b2b;
}

.job-location-row label {
  display: block;
  font-size: 11px;
  color: #828282;
  margin-bottom: 0;
}

.job-location-row span {
  display: block;
  font-size: 13px;
  font-weight: 500;
  color: #2b2b2b;
}

.job-location-row {
  margin-bottom: 7.5px;
}

.job-notes {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.job-notes .job-notes-viewmore {
  display: flex;
  justify-content: space-between;
}

.job-notes .job-notes-row label {
  display: block;
  font-size: 11px;
  color: #828282;
  margin-bottom: 8px;
}

.job-notes .ant-checkbox-wrapper {
  margin-top: 8px;
}

.job-notes .job-notes-row span {
  display: block;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  /*color: #2B2B2B;*/
}

.job-notes .ant-btn-link {
  padding: 0 10px;
  margin: 0;
  height: 18px;
  border: none;
  font-weight: 500;
  font-size: 12px;
  color: #2d9cdb;
}

.worker-card {
  background: #ffffff;
  box-shadow: 2px 4px 9px rgba(199, 199, 199, 0.5);
  border-radius: 4px;
  margin: 16px 0;
  padding: 16px;
  max-width: 250px;
}

.worker-card-header {
  display: flex;
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 15px;
}

.worker-avatar {
  margin-right: 10px;
}

.worker-avatar > span {
  width: 40px;
  height: 40px;
  line-height: 40px;
}

.worker-avatar > img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}

.worker-info {
  flex: 1 1;
  max-width: calc(100% - 50px);
}

.worker-info span {
  font-size: 12px;
  color: #828282;
}

.worker-info .worker-name {
  display: flex;
  align-items: center;
}

.worker-info .worker-name span:first-child {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 60px);
  font-weight: 500;
  font-size: 14px;
  color: #000000;
}

.worker-card .job-assigned {
  padding: 15px 0;
}

.job-assigned .job-assigned-row {
  display: flex;
  justify-content: space-between;
}

.job-assigned .job-assigned-row label,
.job-assigned .job-assigned-row .worker-container-header {
  font-size: 12px;
  color: #828282;
}

.job-assigned .job-assigned-row span {
  font-size: 12px;
  color: #34a6bd;
}

.job-assigned .job-assigned-row:first-child,
.job-assigned .job-assigned-row:nth-child(2),
.job-assigned .job-assigned-row:nth-child(3),
.job-assigned .job-assigned-row:nth-child(4) {
  padding-bottom: 10px;
}

.worker-schedule {
  padding-top: 15px;
}

.worker-schedule--disabled {
  border: 1px solid #828282;
  padding: 1em;
  border-radius: 0.25rem;
  background-color: #fafafa;
  cursor: not-allowed;
}

.worker-schedule .schedule-row {
  position: relative;
  padding-top: 9px;
}

.schedule-row > label {
  position: absolute;
  top: 0;
  font-size: 12px;
  color: #828282;
}

.schedule-row .schedule-row-content {
  display: flex;
  flex-direction: column;
  min-height: 40px;
  padding: 8px 0;
  border-top: 1px solid #f2f2f2;
}

.schedule-row .job-card,
.job-card.dragging {
  border-color: rgb(106, 187, 152);
  height: 66px;
}

#worker-container .job-card {
  margin: 5px 0;
}
.worker-container-row {
  min-height: 5px !important;
}

.schedule-row .job-card-content,
.job-card.dragging .job-card-content {
  padding: 8px;
}

.schedule-row .job-summary,
.job-card.dragging .job-summary {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px !important;
}

.schedule-row .job-summary-row,
.job-card.dragging .job-summary-row {
  display: none;
}

.schedule-row .job-time .job-time-row label {
  font-size: 11px;
}

.job-card .job-main-info {
  display: none;
  width: calc(100% - 60px);
}

.job-card .job-main-info .job-title {
  font-weight: 600;
  font-size: 13px;
  color: #2b2b2b;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.job-card .job-main-info .job-desc {
  font-size: 11px;
  color: #bdbdbd;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.job-card .job-summary > .job-duration {
  display: none;
  font-size: 10px;
  color: #828282;
  text-align: right;
}

.schedule-row .job-card .job-main-info,
.job-card.dragging .job-main-info,
.schedule-row .job-summary > .job-duration,
.job-card.dragging .job-summary > .job-duration {
  display: block;
}

.schedule-row .job-card .ant-btn {
  font-size: 11px;
}

.drop-preview {
  background: rgba(106, 187, 152, 0.1);
  height: 55px;
  border: 1px solid #6abb98;
  border-radius: 4px;
}

.job-cancel-modal {
  text-align: center;
}

.job-cancel-modal p:first-child {
  color: #2d9cdb;
  font-weight: 500;
  font-size: 18px;
}

.job-cancel-modal p:nth-child(2) {
  font-size: 14px;
  color: #828282;
}

.job-checked-in {
  background-color: #b2dfdb;
}

.job-not-checked-in {
  background-color: #fff;
}

.job-not-checked-in > .job-card-content > .job-summary > .job-summary-row {
  padding-top: 3px;
}

.job-checked-out {
  background-color: #f5f5f5;
}

.job-status-cancelled {
  background-color: #e57373;
  border: 1px solid #d32f2f;
}

.job-time-difference {
  display: flex;
  justify-content: space-between;
  font-size: 11px;
}

.job-time-difference-time {
  font-size: 10px;
}
.date-range label {
  text-align: start;
}

.job-checked-in .job-title,
.job-checked-in .job-id,
.job-checked-in .job-desc,
.job-checked-in .job-type,
.job-checked-in .job-company,
.job-checked-in .job-duration,
.job-checked-in .preferred-time > span,
.job-checked-in .job-time-row > label,
.job-checked-in .job-time-row > span,
.job-checked-in .job-location-row > label,
.job-checked-in .job-location-row > span,
.job-checked-in .job-notes-viewmore > label,
.job-checked-in .job-notes-row > span,
.job-checked-in .job-notes-viewmore > .ant-btn,
.job-checked-in .job-card-content > .job-counter > .job-counter-text,
.job-checked-in .job-card-content > .job-time-difference,
.job-checked-in .ant-checkbox-wrapper > span {
  color: #00897b !important;
}

.job-checked-in .job-desc,
.job-checked-in .job-card-content > .job-time-difference > p:first-child,
.job-checked-in .preferred-time > span:first-child,
.job-checked-in .job-time-row > label,
.job-checked-in .job-notes-viewmore > .ant-btn,
.job-checked-in .job-location-row > label {
  color: #25ada0 !important;
}

.job-status-cancelled .job-title,
.job-status-cancelled .job-id,
.job-status-cancelled .job-desc,
.job-status-cancelled .job-type,
.job-status-cancelled .job-company,
.job-status-cancelled .job-duration,
.job-status-cancelled .preferred-time > span,
.job-status-cancelled .job-time-row > label,
.job-status-cancelled .job-time-row > span,
.job-status-cancelled .job-location-row > label,
.job-status-cancelled .job-location-row > span,
.job-status-cancelled .job-notes-viewmore > label,
.job-status-cancelled .job-notes-row > span,
.job-status-cancelled .job-notes-viewmore > .ant-btn,
.job-status-cancelled .job-card-content > .job-counter > .job-counter-text,
.job-status-cancelled .job-card-content > .job-time-difference,
.job-status-cancelled .ant-checkbox-wrapper > span {
  color: #fff !important;
}

.hightlighter-1 {
  border-left: 4px solid #408dbf !important;
}

.hightlighter-2 {
  border-left: 4px solid #5e862d !important;
}

.schedule-content-header-top > .header-dash {
  margin-left: 10px;
  margin-right: 10px;
}

.route-name {
  display: none;
}

.note-group {
  margin: 15px 0;
}

.note-group > h3 {
  margin: 0;
  margin-bottom: 5px;
}

.note-group > textarea.ant-input.ant-input-disabled {
  background-color: #f5f5f5;
  cursor: default !important;
  opacity: 1;
  color: inherit;
}

@media screen and (max-width: 576px) {
  .page-header {
    padding: 0;
  }

  .page-title {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 200px;
    width: 100%;
    border-bottom: 2px solid #ececec;
    position: fixed;
    top: 64px;
    background-color: white;
  }

  .header-drawer-icon {
    flex: 1 1;
  }

  .route-name {
    display: flex;
    justify-content: center;
    text-transform: capitalize;
    font-weight: bold;
    margin-bottom: 0;
    line-height: 24px;
    flex: 3 1;
  }

  .main-header {
    flex: 1 1;
    max-width: 55px;
  }

  .page-title h4 {
    /* font-size: 18px;
        text-align: center;
        padding: 0.5em 0;
        border-bottom: 2px solid #ececec; */
    display: none;
  }

  .page-date-selector {
    width: 70%;
    margin: 1em auto;
  }

  .page-date-selector > .ant-col > .ant-picker > .ant-picker-input > input {
    text-align: center;
  }

  .page-btn > .anticon {
    margin-right: 0;
  }

  .schedule-content-body {
    flex-wrap: unset;
    overflow-x: scroll;
  }

  .vendor-list.fixed {
    position: fixed;
    top: 126px;
  }

  .vendor-list {
    z-index: 1;
  }

  .vendor-list.expanded ~ .schedule-content {
    margin-left: 0;
  }

  .vendor-list.expanded ~ .schedule-content .worker-card {
    width: 24%;
    margin-left: 20px;
    min-width: 265px;
  }

  .vendor-list.expanded ~ .schedule-content .worker-card:nth-child(3n + 1) {
    margin-left: 0;
  }

  .vendor-list.collapsed ~ .schedule-content {
    margin-left: 27px;
  }

  .vendor-list.collapsed ~ .schedule-content .worker-card {
    width: 24%;
    margin-right: 2%;
    min-width: 265px;
  }

  .vendor-list.collapsed ~ .schedule-content .worker-card:nth-child(4n + 1) {
    margin-left: 0;
  }

  .schedule-content .main-content {
    flex: 1 1;
    padding-top: 22px;
    padding-left: 0;
    padding-right: 20px;
  }

  .schedule-content-header {
    padding-left: 16px;
  }

  .schedule-content-header-top {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0;
  }

  .schedule-content-header-top > h3 {
    font-size: 1.25em;
    font-weight: bold;
  }

  .schedule-content-header-top > h4 {
    font-size: 1em;
    font-weight: 700;
    color: #4f4f4f;
  }

  .schedule-content-header-top > .header-dash {
    display: none;
  }

  .schedule-page > .page-content {
    margin-top: 126px;
    z-index: 0;
  }
  .notes-drawer.expanded {
    width: 340px !important;
  }
}

.notes-drawer {
  position: absolute;
  bottom: 0;
  top: 0;
  padding-left: 12px;
  transition: width 0.35s;
  right: 0;
}

.notes-drawer.fixed {
  position: fixed;
  top: 0;
}

.notes-drawer.collapsed {
  width: 27px;
}

.notes-drawer.expanded {
  width: 362px;
}

.notes-drawer-container {
  width: 100%;
  background: white;
  padding: 10px;
  overflow: hidden;
  height: 100%;
  box-shadow: 2px 4px 9px rgba(199, 199, 199, 0.5);
}

.notes-drawer-container,
.header {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.notes-drawer-container ~ .header > h3 {
  font-size: 18px;
  line-height: 21px;
}
.notes-drawer-content {
  width: 330px;
  padding: 24px 10px;
}

.notes-drawer .btn-collapse {
  position: absolute;
  top: 24px;
  left: 0;
  border-radius: 50%;
  box-shadow: 2px 4px 9px rgba(199, 199, 199, 0.5);
  border: none;
  width: 24px;
  height: 24px;
  background: white;
}

.notes-drawer > .ant-btn {
  background-color: white !important;
}

.notes-drawer .btn-collapse span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.notes-drawer .btn-collapse svg {
  width: 10px;
  height: 10px;
  transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  transition: transform 0.35s, -webkit-transform 0.35s;
}

.notes-drawer.collapsed .btn-collapse svg {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.notes-drawer.expanded ~ .schedule-content {
  margin-right: 362px;
}
ul.ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light.dropdown-menu
  > li {
  margin: 2px;
}

.schedular-card-dropdown > .dropdown-menu {
  position: absolute;
  margin: 0;
  padding: 4px 0;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  z-index: 1000;
  outline: none;
  right: 0;
  margin-right: 25px;
  margin-bottom: 20px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
}
.schedular-card-dropdown > .dropdown-menu > .ant-menu-item {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}
.schedular-card-dropdown
  > .dropdown-menu
  > .ant-menu-item
  > .ant-menu-title-content {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.draft-job {
  background: repeating-linear-gradient(
    45deg,
    #ddd,
    #ddd 10px,
    #fff 10px,
    #fff 20px
  );
}
.paused-job {
  background-color: #9b5b9b;
}
.ant-menu-title-content .anticon svg {
  margin-right: 5px;
}
.paused-job .ant-menu-title-content {
  color: #000 !important;
}

.paused-job
  *:not(.paused-job ul.ant-menu span, .paused-job
    ul.ant-menu
    span
    svg, .paused-job ul.ant-menu span path, .paused-job
    .worker-location.permanent-assign-selectBox-div
    *) {
  color: white !important;
}

.color-legand {
  display: flex;
  align-items: flex-end;
}
.color-legand .color-box {
  margin: 0 3px;
  padding: 5px;
}
.color-legand .color-box > p {
  padding-top: 5px;
}
.color-legand .color-box .color-block {
  color: transparent;
}

.clients-page .page-header {
  display: block;
  padding-bottom: 1rem;
}
.clients-page .page-header .ant-col {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 1;
}

body #root .ant-tabs-tab {
  font-size: 16px;
  margin: 0;
  padding: 15px;
}
body #root .ant-tabs-tab-active {
  color: #2d9cdb;
  font-weight: 500;
  border-bottom: 3px solid #2d9cdb;
}

.client-profile-page .client-info {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-bottom: 50px;
}

.client-profile-page .client-info .user-avatar {
    padding: 0 15px;
    display: flex;
    align-items: center;
}
.client-profile-page .user-avatar-info {
    padding-left: 15px;
}
.client-profile-page .user-avatar-info .user-name {
    font-weight: 500;
    font-size: 18px;
    color: #2B2B2B;
    margin-bottom: 5px;
}
.client-profile-page .user-avatar-info .client-id {
    color: #4F4F4F;
}

.client-profile-page .user-info-block {
    padding: 0 15px;
}
.client-profile-page .user-info-block label {
    display: block;
    font-weight: 500;
    font-size: 16px;
    color: #2B2B2B;
}
.client-profile-page .user-info-block span {
    display: block;
    font-size: 16px;
    color: #828282;
    margin-top: 10px;
}


.delete-address-modal .ant-modal-body {
    text-align: center;
}
.delete-address-modal .ant-modal-footer {
    border: none;
    padding: 24px;
}
.delete-address-modal p {
    margin: 0;
}
.delete-address-modal .address-info {
    padding: 30px 0;
    color: #2B2B2B;
}
.delete-address-modal .main-message {
    max-width: 350px;
    margin: auto;
    font-weight: 500;
    font-size: 18px;
    color: #2D9CDB;
}
.delete-address-modal .ant-modal-footer .ant-btn {
    width: 120px;
    border-radius: 5px;
    border: none;
}
.delete-address-modal .ant-modal-footer .ant-btn-primary {
    background: #2D9CDB;
}

.extra-header {
    background: #FFFFFF;
    padding: 30px 40px 0 40px;
}
.client-profile-page .extra-header .client-info {
    justify-content: space-between;
    padding-bottom: 15px;
}
.job-profile-page .job-info {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
}
.job-profile-page .job-title .job-info-block {
  padding: 0;
}
.job-profile-page .job-other-info {
  display: flex;
}
.job-profile-page .job-info-block {
  padding: 0 15px;
}
.job-profile-page .job-info-block label {
  display: block;
  font-weight: 500;
  font-size: 16px;
  color: #2b2b2b;
}
.job-profile-page .job-info-block span {
  display: block;
  font-size: 16px;
  color: #828282;
  margin-top: 10px;
}

.job-info-top {
  border-bottom: 1px solid #f2f2f2;
}
.job-detail-block {
  padding: 15px;
}
.job-detail-block label {
  display: block;
  font-weight: 500;
  font-size: 16px;
  color: #2b2b2b;
  margin-bottom: 10px;
}
.job-detail-block span {
  display: block;
  color: #828282;
  font-size: 16px;
}

.page-sub-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.page-sub-header h4 {
  margin: 0;
  font-size: 20px;
}

.assign-worker-modal .ant-modal-header {
  border: none;
}
.assign-worker-modal .ant-modal-footer {
  border: none;
}
.assign-worker-modal .date-range {
  padding-bottom: 30px;
  border-bottom: 1px solid #e0e0e0;
}
.assign-worker-modal label {
  font-weight: 500;
  font-size: 16px;
  color: #2b2b2b;
}
.assign-worker-modal .form-row {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}
.assign-worker-modal .form-row > span {
  margin: 0 24px;
  font-size: 16px;
  color: black;
}
.assign-worker-modal .assign-worker {
  padding-top: 30px;
}
.assign-worker-modal .form-group + .form-group {
  margin-top: 24px;
}


.admin-products-page-btn{
    background-color: #DC4E4E !important;
    color: white !important;
}

td, th {
    vertical-align:top;
}
.ant-list-item-meta-description
{
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', "tnum", "tnum";
    height: 24px;
    line-height: 24px;
}
ul.ant-list-items > li:first-child{
    padding-top: 0;
}
.ant-input-number{
    min-width: 100%;
}

.product-create-field-title-container {
    display: flex;
    margin-top: -2px;
    
}

.product-create-field-title {
    margin: 0;
    padding: 0;
    color: #2B2B2B;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
}

.product-create-field-title-btn {
    background: transparent;
    outline: none;
    border: none;
    color: #1890ff;
    cursor: pointer;
    
}

/* .ant-upload-list-picture-card .ant-tooltip-inner{
    display: none;
} */
 
.ant-upload-list-picture-card .ant-upload-list-item-error{
    border-color: #bebebe !important;
 }
.radioImage .ant-checkbox {
    display: none;
}
.ant-checkbox-wrapper-checked.radioImage .ant-card.ant-card-bordered {
    border-color: #1597FF;
}
.radioImage.disabled:not(.ant-checkbox-wrapper-checked) {
 pointer-events: none;
}
.ant-checkbox-wrapper+.ant-checkbox-wrapper {
    margin-left: 0 !important;
}
td, th {
    vertical-align:top;
}
.ant-list-item-meta-description
{
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', "tnum", "tnum";
    height: 24px;
    line-height: 24px;
}
ul.ant-list-items > li:first-child{
    padding-top: 0;
}
.ant-input-number{
    min-width: 100%;
}
.radioImage{
    height: 150px !important;
  }
td,
th {
  vertical-align: top;
}
.ant-list-item-meta-description {
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', 'tnum', "tnum";
  height: 24px;
  line-height: 24px;
}
ul.ant-list-items > li:first-child {
  padding-top: 0;
}
.ant-input-number {
  min-width: 100%;
}
.product-create-field-title-container {
  display: flex;
  margin-top: -2px;
}
.radioImage{
  height: 150px !important;
}
.vendor-profile-page .vendor-info {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-bottom: 50px;
}

.vendor-profile-page .vendor-info .vendor-avatar {
    padding: 0 15px;
    display: flex;
    align-items: center;
}
.vendor-profile-page .vendor-avatar-info {
    padding-left: 15px;
}
.vendor-profile-page .vendor-avatar-info .vendor-name {
    font-weight: 500;
    font-size: 18px;
    color: #2B2B2B;
    margin-bottom: 5px;
}
.vendor-profile-page .vendor-avatar-info .vendor-id {
    color: #4F4F4F;
}

.vendor-profile-page .vendor-info-block {
    padding: 0 15px;
}
.vendor-profile-page .vendor-info-block label {
    display: block;
    font-weight: 500;
    font-size: 16px;
    color: #2B2B2B;
}
.vendor-profile-page .vendor-info-block span {
    display: block;
    font-size: 16px;
    color: #828282;
    margin-top: 10px;
}
.vendor-profile-page .vendor-info {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-bottom: 50px;
}

.vendor-profile-page .vendor-info .vendor-avatar {
    padding: 0 15px;
    display: flex;
    align-items: center;
}
.vendor-profile-page .vendor-avatar-info {
    padding-left: 15px;
}
.vendor-profile-page .vendor-avatar-info .vendor-name {
    font-weight: 500;
    font-size: 18px;
    color: #2B2B2B;
    margin-bottom: 5px;
}
.vendor-profile-page .vendor-avatar-info .vendor-id {
    color: #4F4F4F;
}

.vendor-profile-page .vendor-info-block {
    padding: 0 15px;
}
.vendor-profile-page .vendor-info-block label {
    display: block;
    font-weight: 500;
    font-size: 16px;
    color: #2B2B2B;
}
.vendor-profile-page .vendor-info-block span {
    display: block;
    font-size: 16px;
    color: #828282;
    margin-top: 10px;
}
body .ant-btn-primary {
    border-radius: 4px;
    background: #2D9CDB;
    border-color: #2D9CDB;
}

body .ant-btn-default {
    border-color: #2D9CDB;
    color: #2D9CDB;
    border-radius: 4px;
}
.page-header .page-header-actions {
    display: flex;
    align-items: center;
}

.page-header .page-header-actions .page-header-action,
.page-header .page-header-actions button {
    margin-right: 15px;
}
.page-header .page-header-actions .page-header-action svg {
    color: #BDBDBD;
    font-Size: 18px;
    cursor: pointer;
}
.page-header .page-header-actions .ant-input-search {
    width: 100%;
    padding: 6px 18px;
    border: 1px solid #F2F2F2;
    border-radius: 25px;
    box-shadow: 2px 4px 9px rgba(199, 199, 199, 0.5);
    box-sizing: border-box;
}

.page-content {
    padding: 30px;
}

.ant-table-action-cell {
    display: flex;
    align-items: center;
}
.ant-table-action-cell svg {
    color: #BDBDBD;
    font-size: 18px;
}
.ant-table-action-cell span + span {
    margin-left: 15px;
}

.delete-user-modal .ant-modal-body {
    text-align: center;
}
.delete-user-modal .ant-modal-footer {
    border: none;
    padding: 24px;
}
.delete-user-modal p {
    margin: 0;
}
.delete-user-modal .user-info {
    margin: 24px;
}
.delete-user-modal .username {
    margin-top: 16px;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
}
.delete-user-modal .main-message {
    max-width: 350px;
    margin: auto;
    font-weight: 500;
    font-size: 18px;
    color: #2D9CDB;
}
.delete-user-modal .ant-modal-footer .ant-btn {
    width: 120px;
    border-radius: 5px;
    border: none;
}
.delete-user-modal .ant-modal-footer .ant-btn-primary {
    background: #2D9CDB;
}

.drawer-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
}
.drawer-header h3 {
    color: #2B2B2B;
    font-size: 22px;
    margin: 0;
}
.drawer-header svg {
    color: #BDBDBD;
    font-size: 18px;
}
.drawer-header .action-primary svg {
    color: #2D9CDB;
    margin-right: 15px;
}
.drawer-body .user-avatar {
    text-align: center;
    padding: 30px 0;
}
.drawer-body .user-avatar .avatar-uploader {
    width: auto;
}
.drawer-body .username {
    color: #2B2B2B;
    font-size: 20px;
    font-weight: 500;
    margin-top: 15px;
}
.drawer-body .user-status {
    display: flex;
    align-items: center;
    justify-content: center;
}
.drawer-body .user-status .dot {
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
}
.drawer-body .user-status .dot.active {
    background: #6ABB98;
}
.drawer-body .user-status .dot.inactive {
    background: #F1B13B;
}
.drawer-body .user-status .dot.suspended {
    background: #DF5E67;
}
.drawer-body .user-status span:last-child {
    font-size: 16px;
    color: #2B2B2B;
    margin-left: 10px;
    text-transform: capitalize;
}
.drawer-body .user-info-row {
    padding: 10px;
}
.drawer-body .user-info-row label {
    display: block;
    font-weight: 500;
    font-size: 16px;
    color: #2B2B2B;
}
.drawer-body .user-info-row span {
    display: block;
    font-size: 16px;
    color: #828282;
}
.drawer-body .avatar-uploader .ant-upload-select {
    border: none;
    margin: 0;
    background: transparent;
}

.drawer-body .form-group {
    padding: 6px 0;
}
.drawer-body .form-group > label {
    display: block;
    font-weight: 500;
    font-size: 16px;
    color: #2B2B2B;
}
.drawer-body .form-group > span {
    font-size: 16px;
    color: #2B2B2B;
}
.drawer-body form .ant-btn-block {
    margin-top: 15px;
}
.drawer-body form .ant-btn-primary {
    background: #2D9CDB;
    border-radius: 7px;
    border-color: #2D9CDB;
    height: 42px;
    font-weight: 500;
    font-size: 16px;
    text-transform: uppercase;
}
.drawer-body .password-reset-form {
    margin-top: 30px;
}

.role-perm-heading {
    margin-top: 1em;
    font-weight: bold;
}

.permission-tabs-container {
    margin-top: 0.35em;
    display: flex;
    flex-wrap: wrap;
}

.permission-tab {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 1em !important;
    text-transform: capitalize;
    margin-bottom: 0.5em !important;
}
body .ant-btn-primary {
  border-radius: 4px;
  background: #2d9cdb;
  border-color: #2d9cdb;
}

body .ant-btn-default {
  border-color: #2d9cdb;
  color: #2d9cdb;
  border-radius: 4px;
}
.page-header .page-header-actions {
  display: flex;
  align-items: center;
}

.page-header .page-header-actions .page-header-action,
.page-header .page-header-actions button {
  margin-right: 15px;
}
.page-header .page-header-actions .page-header-action svg {
  color: #bdbdbd;
  font-size: 18px;
  cursor: pointer;
}
.page-header .page-header-actions .ant-input-search {
  width: 100%;
  padding: 6px 18px;
  border: 1px solid #f2f2f2;
  border-radius: 25px;
  box-shadow: 2px 4px 9px rgba(199, 199, 199, 0.5);
  box-sizing: border-box;
}

.page-content {
  padding: 30px;
}

.ant-table-action-cell {
  display: flex;
  align-items: center;
}
.ant-table-action-cell svg {
  color: #bdbdbd;
  font-size: 18px;
}
.ant-table-action-cell span + span {
  margin-left: 15px;
}

.delete-user-modal .ant-modal-body {
  text-align: center;
}
.delete-user-modal .ant-modal-footer {
  border: none;
  padding: 24px;
}
.delete-user-modal p {
  margin: 0;
}
.delete-user-modal .user-info {
  margin: 24px;
}
.delete-user-modal .username {
  margin-top: 16px;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}
.delete-user-modal .main-message {
  max-width: 350px;
  margin: auto;
  font-weight: 500;
  font-size: 18px;
  color: #2d9cdb;
}
.delete-user-modal .ant-modal-footer .ant-btn {
  width: 120px;
  border-radius: 5px;
  border: none;
}
.delete-user-modal .ant-modal-footer .ant-btn-primary {
  background: #2d9cdb;
}

.drawer-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}
.drawer-header .drawer-heading {
  font-size: 18px;
  margin: 0;
  background-color: #1890fe;
  color: white;
  padding: 4px 8px;
  border-color: #1890fe;
  border-radius: 4px;
  font-weight: 600;
}
.drawer-header svg {
  color: #bdbdbd;
  font-size: 18px;
}
.drawer-header .action-primary svg {
  color: #2d9cdb;
  margin-right: 15px;
}
.drawer-body .user-avatar {
  text-align: center;
  padding: 30px 0;
}
.drawer-body .user-avatar .avatar-uploader {
  width: auto;
}
.drawer-body .username {
  color: #2b2b2b;
  font-size: 20px;
  font-weight: 500;
  margin-top: 15px;
}
.drawer-body .user-status {
  display: flex;
  align-items: center;
  justify-content: center;
}
.drawer-body .user-status .dot {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.drawer-body .user-status .dot.active {
  background: #6abb98;
}
.drawer-body .user-status .dot.inactive {
  background: #f1b13b;
}
.drawer-body .user-status .dot.suspended {
  background: #df5e67;
}
.drawer-body .user-status span:last-child {
  font-size: 16px;
  color: #2b2b2b;
  margin-left: 10px;
  text-transform: capitalize;
}
.drawer-body .user-info-row {
  padding: 10px 0px !important;
}
.drawer-body .user-info-row label {
  display: block;
  font-weight: 500;
  font-size: 16px;
  color: #2b2b2b;
}
.drawer-body .user-info-row span {
  display: block;
  font-size: 16px;
  color: #828282;
}
.drawer-body .avatar-uploader .ant-upload-select {
  border: none;
  margin: 0;
  background: transparent;
}

.drawer-body .form-group {
  padding: 6px 0;
}
.drawer-body .form-group > label {
  display: block;
  font-weight: 500;
  font-size: 16px;
  color: #2b2b2b;
}
.drawer-body .form-group > span {
  font-size: 16px;
  color: #2b2b2b;
}
.drawer-body form .ant-btn-block {
  margin-top: 15px;
}
.drawer-body form .ant-btn-primary {
  background: #2d9cdb;
  border-radius: 7px;
  border-color: #2d9cdb;
  height: 42px;
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
}
.drawer-body .password-reset-form {
  margin-top: 30px;
}

.cursor-pointer {
  cursor: pointer;
}

.user-info-row.image-detail {
  display: flex;
  justify-content: space-between;
}

body .ant-btn-primary {
  border-radius: 4px;
  background: #2d9cdb;
  border-color: #2d9cdb;
}

body .ant-btn-default {
  border-color: #2d9cdb;
  color: #2d9cdb;
  border-radius: 4px;
}
.page-header .page-header-actions {
  display: flex;
  align-items: center;
}

.page-header .page-header-actions .page-header-action,
.page-header .page-header-actions button {
  margin-right: 15px;
}
.page-header .page-header-actions .page-header-action svg {
  color: #bdbdbd;
  font-size: 18px;
  cursor: pointer;
}
.page-header .page-header-actions .ant-input-search {
  width: 100%;
  padding: 6px 18px;
  border: 1px solid #f2f2f2;
  border-radius: 25px;
  box-shadow: 2px 4px 9px rgba(199, 199, 199, 0.5);
  box-sizing: border-box;
}

.page-content {
  padding: 30px;
}

.ant-table-action-cell {
  display: flex;
  align-items: center;
}
.ant-table-action-cell svg {
  color: #bdbdbd;
  font-size: 18px;
}
.ant-table-action-cell span + span {
  margin-left: 15px;
}

.delete-user-modal .ant-modal-body {
  text-align: center;
}
.delete-user-modal .ant-modal-footer {
  border: none;
  padding: 24px;
}
.delete-user-modal p {
  margin: 0;
}
.delete-user-modal .user-info {
  margin: 24px;
}
.delete-user-modal .username {
  margin-top: 16px;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}
.delete-user-modal .main-message {
  max-width: 350px;
  margin: auto;
  font-weight: 500;
  font-size: 18px;
  color: #2d9cdb;
}
.delete-user-modal .ant-modal-footer .ant-btn {
  width: 120px;
  border-radius: 5px;
  border: none;
}
.delete-user-modal .ant-modal-footer .ant-btn-primary {
  background: #2d9cdb;
}

.drawer-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}
.drawer-header .drawer-heading {
  font-size: 18px;
  margin: 0;
  background-color: #1890fe;
  color: white;
  padding: 4px 8px;
  border-color: #1890fe;
  border-radius: 4px;
  font-weight: 600;
}
.drawer-header svg {
  color: #bdbdbd;
  font-size: 18px;
}
.drawer-header .action-primary svg {
  color: #2d9cdb;
  margin-right: 15px;
}
.drawer-body .user-avatar {
  text-align: center;
  padding: 30px 0;
}
.drawer-body .user-avatar .avatar-uploader {
  width: auto;
}
.drawer-body .username {
  color: #2b2b2b;
  font-size: 20px;
  font-weight: 500;
  margin-top: 15px;
}
.drawer-body .user-status {
  display: flex;
  align-items: center;
  justify-content: center;
}
.drawer-body .user-status .dot {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.drawer-body .user-status .dot.active {
  background: #6abb98;
}
.drawer-body .user-status .dot.inactive {
  background: #f1b13b;
}
.drawer-body .user-status .dot.suspended {
  background: #df5e67;
}
.drawer-body .user-status span:last-child {
  font-size: 16px;
  color: #2b2b2b;
  margin-left: 10px;
  text-transform: capitalize;
}
.drawer-body .user-info-row {
  padding: 10px 0px !important;
}
.drawer-body .user-info-row label {
  display: block;
  font-weight: 500;
  font-size: 16px;
  color: #2b2b2b;
}
.drawer-body .user-info-row span {
  display: block;
  font-size: 16px;
  color: #828282;
}
.drawer-body .avatar-uploader .ant-upload-select {
  border: none;
  margin: 0;
  background: transparent;
}

.drawer-body .form-group {
  padding: 6px 0;
}
.drawer-body .form-group > label {
  display: block;
  font-weight: 500;
  font-size: 16px;
  color: #2b2b2b;
}
.drawer-body .form-group > span {
  font-size: 16px;
  color: #2b2b2b;
}
.drawer-body form .ant-btn-block {
  margin-top: 15px;
}
.drawer-body form .ant-btn-primary {
  background: #2d9cdb;
  border-radius: 7px;
  border-color: #2d9cdb;
  height: 42px;
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
}
.drawer-body .password-reset-form {
  margin-top: 30px;
}

.cursor-pointer {
  cursor: pointer;
}

.user-info-row.image-detail {
  display: flex;
  justify-content: space-between;
}

:root {
  --color1: #2f80ed;
  --color2: #2d9cdb;
  --color3: #6abb98;
  --color4: #2b2b2b;
  --color5: #828282;
  --color6: #f1b13b;
  --color7: #df5e67;
}

.transaction-container {
  width: 100%;
  font-family: 'Roboto', sans-serif;
}

.transaction-content-container {
  margin: 2.5em;
}

.font-bold {
  font-weight: bold;
  letter-spacing: 1px;
}

.tran-type-debit,
.tran-status-success {
  color: #6abb98;
  color: var(--color3);
}

.tran-type-credit {
  color: #df5e67;
  color: var(--color7);
}

.link:hover {
  text-decoration: underline;
}

.table-column-title {
  color: #2f80ed;
  color: var(--color1);
  font-weight: normal;
}

.link-disabled,
.link-disabled:hover {
  color: #828282;
  color: var(--color5);
  cursor: not-allowed;
}

.hide-column {
  display: none;
}

.order-item-table-heading-container {
  padding: 1em 0 0.5em 0;
}

.order-item-table-heading {
  padding: 0;
  margin: 0;
  font-weight: bold;
  font-size: 1.25em;
}

.order-item-table-text {
  padding: 0;
  margin: 0;
  margin-top: 1em;
  font-size: 0.875em;
}

.order-item-table-footer-container {
  display: flex;
}

.order-item-table-footer-text {
  padding: 0;
  margin: 0;
  font-weight: bold;
  font-size: 1.15em;
}

.oi-table-footer-heading {
  flex: 4.7 1;
  text-align: right;
  padding-right: 2em;
}

.oi-table-footer-text {
  flex: 1 1;
}

.color-yellow {
  color: #f1b13b;
  color: var(--color6);
}

.color-green {
  color: #6abb98;
  color: var(--color3);
}

.color-red {
  color: #df5e67;
  color: var(--color7);
}

.color-gray {
  color: #828282;
  color: var(--color5);
}
.credit:first-child {
  color: #dc4e4e;
}
.debit:first-child {
  color: rgb(106, 187, 152);
}

:root {
  --color1: #2f80ed;
  --color2: #2d9cdb;
  --color3: #6abb98;
  --color4: #2b2b2b;
  --color5: #828282;
  --color6: #f1b13b;
  --color7: #df5e67;
}

.order-container {
  width: 100%;
  font-family: 'Roboto', sans-serif;
}

.order-content-container {
  margin: 2.5em;
}

.font-bold {
  font-weight: bold;
  letter-spacing: 1px;
}

.tran-type-debit,
.tran-status-success {
  color: #6abb98;
  color: var(--color3);
}

.tran-type-credit {
  color: #df5e67;
  color: var(--color7);
}

.link:hover {
  text-decoration: underline;
}

.table-column-title {
  color: #2f80ed;
  color: var(--color1);
  font-weight: normal;
}
.credit:first-child {
  color: #dc4e4e;
}
.debit:first-child {
  color: rgb(106, 187, 152);
}

body .ant-btn-primary {
  border-radius: 4px;
  background: #2d9cdb;
  border-color: #2d9cdb;
}

body .ant-btn-default {
  border-color: #2d9cdb;
  color: #2d9cdb;
  border-radius: 4px;
}
.page-header .page-header-actions {
  display: flex;
  align-items: center;
}

.page-header .page-header-actions .page-header-action,
.page-header .page-header-actions button {
  margin-right: 15px;
}
.page-header .page-header-actions .page-header-action svg {
  color: #bdbdbd;
  font-size: 18px;
  cursor: pointer;
}
.page-header .page-header-actions .ant-input-search {
  width: 100%;
  padding: 6px 18px;
  border: 1px solid #f2f2f2;
  border-radius: 25px;
  box-shadow: 2px 4px 9px rgba(199, 199, 199, 0.5);
  box-sizing: border-box;
}

.page-content {
  padding: 30px;
}

.ant-table-action-cell {
  display: flex;
  align-items: center;
}
.ant-table-action-cell svg {
  color: #bdbdbd;
  font-size: 18px;
}
.ant-table-action-cell span + span {
  margin-left: 15px;
}

.delete-user-modal .ant-modal-body {
  text-align: center;
}
.delete-user-modal .ant-modal-footer {
  border: none;
  padding: 24px;
}
.delete-user-modal p {
  margin: 0;
}
.delete-user-modal .user-info {
  margin: 24px;
}
.delete-user-modal .username {
  margin-top: 16px;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}
.delete-user-modal .main-message {
  max-width: 350px;
  margin: auto;
  font-weight: 500;
  font-size: 18px;
  color: #2d9cdb;
}
.delete-user-modal .ant-modal-footer .ant-btn {
  width: 120px;
  border-radius: 5px;
  border: none;
}
.delete-user-modal .ant-modal-footer .ant-btn-primary {
  background: #2d9cdb;
}

.drawer-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}
.drawer-header h3 {
  color: #2b2b2b;
  font-size: 22px;
  margin: 0;
}
.drawer-header svg {
  color: #bdbdbd;
  font-size: 18px;
}
.drawer-header .action-primary svg {
  color: #2d9cdb;
  margin-right: 15px;
}
.drawer-body .user-avatar {
  text-align: center;
  padding: 30px 0;
}
.drawer-body .user-avatar .avatar-uploader {
  width: auto;
}
.drawer-body .username {
  color: #2b2b2b;
  font-size: 20px;
  font-weight: 500;
  margin-top: 15px;
}
.drawer-body .user-status {
  display: flex;
  align-items: center;
  justify-content: center;
}
.drawer-body .user-status .dot {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.drawer-body .user-status .dot.active {
  background: #6abb98;
}
.drawer-body .user-status .dot.inactive {
  background: #f1b13b;
}
.drawer-body .user-status .dot.suspended {
  background: #df5e67;
}
.drawer-body .user-status span:last-child {
  font-size: 16px;
  color: #2b2b2b;
  margin-left: 10px;
  text-transform: capitalize;
}
.drawer-body .user-info-row {
  padding: 10px;
}
.drawer-body .user-info-row label {
  display: block;
  font-weight: 500;
  font-size: 16px;
  color: #2b2b2b;
}
.drawer-body .user-info-row span {
  display: block;
  font-size: 16px;
  color: #828282;
}
.drawer-body .avatar-uploader .ant-upload-select {
  border: none;
  margin: 0;
  background: transparent;
}

.drawer-body .form-group {
  padding: 6px 0;
}
.drawer-body .form-group > label {
  display: block;
  font-weight: 500;
  font-size: 16px;
  color: #2b2b2b;
}
.drawer-body .form-group > span {
  font-size: 16px;
  color: #2b2b2b;
}
.drawer-body form .ant-btn-block {
  margin-top: 15px;
}
.drawer-body form .ant-btn-primary {
  background: #2d9cdb;
  border-radius: 7px;
  border-color: #2d9cdb;
  height: 42px;
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
}
.drawer-body .password-reset-form {
  margin-top: 30px;
}

.cursor-pointer {
  cursor: pointer;
}

.announcement-tab-container > .ant-card-body {
    padding: 1em 0 0 0;
}

.announcement-tabs > .ant-tabs-bar {
    padding: 0;
    margin: 0;
}

.announcement-tabs > .ant-tabs-bar {
    padding: 0;
    margin: 0;
}

.tab-container {
    margin: 2em !important;
    border-radius: 7.5px !important;
}

.card-heading {
    padding: 0;
    margin: 0;
    color: #4f4f4f;
    font-weight: bold;
    padding-bottom: 17px;
    border-bottom: 1px solid #f2f2f2;
}

.field-container {
    display: flex;
    flex-direction: column;
    margin-top: 2em;
}

.field-label {
    padding: 0;
    margin: 0;
    font-size: 1em;
    font-weight: bold;
    letter-spacing: 0.25px;
    margin-bottom: 0.25em;
}

.field-label-required {
    color: #2d9cdb;
}

.text-area-char-count {
    padding: 0;
    margin: 0;
    margin-top: 0.5em;
    text-align: right;
    color: #bdbdbd;
}

.action-btn-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 2em;
}

.btn {
    width: 6em;
    margin-left: 1em;
}

.btn-reset {
    color: #2d9cdb;
}

.table-column-title {
    color: #2f80ed;
    font-weight: 800;
}

.mt2 {
    margin-top: 2em;
}

.checkbox-c {
    font-weight: 700;
    color: #000;
}

.modal-header-title,
.modal-header-text {
    padding: 0;
    margin: 0;
}

.modal-header-title {
    font-size: 1.25em;
    font-weight: bold;
}

.modal-header-text {
    font-size: 0.875em;
    margin-top: 0.5em;
}

.modal-body-container {
    display: flex;
    flex-direction: column;
}

.modal-body-head-container {
    display: flex;
}

.modal-body-head {
    flex: 1 1;
}

.modal-body-title {
    font-weight: bold;
}

.text-ellipses {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 575px;
    white-space: nowrap;
}

.recipient-status-pending {
    color: #828282;
    font-weight: 500;
}

.recipient-status-delivered {
    color: #6abb98;
    font-weight: 500;
}

#dept-daily,#reportable-startdate,#reportable-enddate{
    padding:4px 15px !important
}
#reportable-customer{
    padding: 8px 15px !important;
}
#reportTable-search{
    height: 47px !important;
}
.reports-page .page-header {
  display: block;
  padding-bottom: 0;
}
.reports-page .page-header .ant-col {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 1;
}
.reports-page .page-content {
  margin-top: 0;
}

.reports-page .filters-container {
  margin-bottom: 2em;
  padding-bottom: 2em;
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid #e4e3e3;
}

.reports-page .filters-container .filter-search {
  flex: 1 1;
  max-width: 300px;
}

.reports-page .filters-container .vendor-select {
  margin-right: 1em;
}

.reports-page .filters-container .filter-search .ant-select-selector {
  height: auto;
  padding: 10px 15px !important;
  border-radius: 5px;
  border: 1px solid #f2f2f2;
  align-items: center !important;
}

.reports-page .filters-container .ant-picker-large {
  border: 1px solid #f2f2f2;
}

.reports-page .filters-container .ant-picker-focused {
  border-color: #40a9ff;
}

.reports-page
  .filters-container
  .ant-form-item-with-help
  .ant-form-item-control
  .ant-form-item-explain {
  margin-top: 0.25em;
  margin-left: 1.25em;
}

.reports-page .filters-container .date-picker {
  flex: 1 1;
  padding: 10px 15px !important;
  border-radius: 5px;
  max-width: 232px;
  margin-left: 1em;
  align-items: center !important;
}

.reports-page .filters-container .client-address-select {
  flex: 1 1;
  border-radius: 5px;
  max-width: 300px;
  margin-left: 1em;
}

.reports-page .filters-container .date-picker:hover {
  border-color: #40a9ff;
}

.reports-page .filters-container .date-picker .ant-picker-input input {
  font-size: 14px;
  margin-left: 2.5em;
}

.reports-page .filters-container .client-address-select .ant-select-selector {
  padding: 6.5px 15px !important;
}

/* .reports-page .filters-container .client-address-select .ant-select-selector .ant-select-selection-item {
    display: none;
} */

.reports-page
  .filters-container
  .filter-search
  .ant-select-selector
  .ant-select-selection-search {
  margin-left: 2.5em;
}

.reports-page .filters-container .filter-search-btn {
  margin-left: 1.25em;
  width: 100px;
  margin-top: 1px;
}

.reports-page
  .filters-container
  .filter-search
  .ant-select-selector
  .ant-select-selection-search
  .ant-select-selection-search-input {
  height: 100%;
}

.reports-page
  .filters-container
  .filter-search
  .ant-select-selector
  .ant-select-selection-placeholder {
  margin-left: 2.5em;
  line-height: unset;
}

.reports-page
  .filters-container
  .client-address-select
  .ant-select-selector
  .ant-select-selection-placeholder {
  margin-left: 0.5em;
  line-height: unset;
}

.reports-page
  .filters-container
  .filter-search
  .ant-select-selector
  .ant-select-selection-item {
  margin-left: 2.5em;
  line-height: unset;
}

.reports-page
  .filters-container
  .client-address-select
  .ant-select-selector
  .ant-select-selection-item {
  margin-left: 0;
  line-height: unset;
}

.reports-page
  .filters-container
  .client-address-select
  .ant-select-selector
  .ant-select-selection-search {
  margin-left: 0;
  line-height: unset;
}

.reports-page .filters-container .filter-search .ant-select-arrow {
  top: 46%;
  right: 0;
  left: 18px;
}

.reports-page .filters-container .vendor-select .ant-select-arrow {
  top: 35%;
}

.reports-page
  .filters-container
  .date-picker
  .ant-picker-input
  .ant-picker-suffix {
  right: 0;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  position: absolute;
}

.reports-page
  .filters-container
  .filter-search
  .ant-select-arrow
  .anticon-search
  svg,
.reports-page
  .filters-container
  .date-picker
  .ant-picker-input
  .ant-picker-suffix
  .anticon-calendar
  svg {
  height: 1.25em;
  width: 1.25em;
  fill: #2d9cdb;
}

body #root .ant-tabs-tab {
  font-size: 16px;
  margin: 0;
  padding: 15px;
}
body #root .ant-tabs-tab-active {
  color: #2d9cdb;
  font-weight: 500;
  border-bottom: 3px solid #2d9cdb;
}

.table-no-data-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60vmin;
}

.table-no-data-container > .no-data-message-container {
  margin-top: 0.5em;
}

.table-no-data-container > .no-data-message-container > .no-data-message {
  display: flex;
  padding: 0;
  margin: 0;
  justify-content: center;
  color: #4f4f4f;
  font-size: 0.9em;
}

.customer-info-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5em 1em;
}

.customer-info-container > .customer-info {
  display: flex;
  align-items: center;
  width: 100%;
}

.customer-info-container > .customer-period-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.customer-info-container > .customer-info > .customer-info-name-container {
  display: flex;
  flex-direction: column;
  margin-left: 1.25em;
  width: 100%;
}

.customer-info-container
  > .customer-info
  > .customer-info-name-container
  > .page-date-selector {
  width: 200px;
  margin-top: 5px;
}
.page-date-selector .ant-picker {
  padding: 4px 0px !important;
}
.customer-info-container
  > .customer-info
  > .customer-info-name-container
  > .page-date-selector
  > .ant-col
  > .ant-picker
  > .ant-picker-input
  > input {
  text-align: center;
}

.customer-info-container
  > .customer-info
  > .customer-info-name-container
  > .customer-info-name,
.customer-period-info > .customer-period-heading {
  padding: 0;
  margin: 0;
  color: #2b2b2b;
  font-size: 1.25em;
  font-weight: bold;
}

.customer-period-info > .customer-period-heading {
  font-size: 0.85em;
}

.customer-info-container
  > .customer-info
  > .customer-info-name-container
  > .customer-info-id,
.customer-period-info > .customer-period-date {
  padding: 0;
  margin: 0;
  color: #4f4f4f;
  font-size: 0.85em;
  font-weight: normal;
}

td,
th {
  vertical-align: top;
}
.ant-list-item-meta-description {
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', 'tnum', "tnum";
  height: 24px;
  line-height: 24px;
}
ul.ant-list-items > li:first-child {
  padding-top: 0;
}
.ant-input-number {
  min-width: 100%;
}
.radioImage{
  height: 150px !important;
}
.vendor-profile-page .vendor-info {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-bottom: 50px;
}

.vendor-profile-page .vendor-info .vendor-avatar {
    padding: 0 15px;
    display: flex;
    align-items: center;
}
.vendor-profile-page .vendor-avatar-info {
    padding-left: 15px;
}
.vendor-profile-page .vendor-avatar-info .vendor-name {
    font-weight: 500;
    font-size: 18px;
    color: #2B2B2B;
    margin-bottom: 5px;
}
.vendor-profile-page .vendor-avatar-info .vendor-id {
    color: #4F4F4F;
}

.vendor-profile-page .vendor-info-block {
    padding: 0 15px;
}
.vendor-profile-page .vendor-info-block label {
    display: block;
    font-weight: 500;
    font-size: 16px;
    color: #2B2B2B;
}
.vendor-profile-page .vendor-info-block span {
    display: block;
    font-size: 16px;
    color: #828282;
    margin-top: 10px;
}
.vendor-profile-page .vendor-info {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-bottom: 50px;
}

.vendor-profile-page .vendor-info .vendor-avatar {
    padding: 0 15px;
    display: flex;
    align-items: center;
}
.vendor-profile-page .vendor-avatar-info {
    padding-left: 15px;
}
.vendor-profile-page .vendor-avatar-info .vendor-name {
    font-weight: 500;
    font-size: 18px;
    color: #2B2B2B;
    margin-bottom: 5px;
}
.vendor-profile-page .vendor-avatar-info .vendor-id {
    color: #4F4F4F;
}

.vendor-profile-page .vendor-info-block {
    padding: 0 15px;
}
.vendor-profile-page .vendor-info-block label {
    display: block;
    font-weight: 500;
    font-size: 16px;
    color: #2B2B2B;
}
.vendor-profile-page .vendor-info-block span {
    display: block;
    font-size: 16px;
    color: #828282;
    margin-top: 10px;
}
* {
  font-family: Roboto;
}
:root {
  --primary: #2f80ed;
}
body {
  height: auto;
}

.ant-layout .ant-layout-header {
  background-color: white;
  box-shadow: 2px 2px 9px rgba(178, 178, 178, 0.2);
  z-index: 9 !important;
}

.ant-pro-sider-menu-logo {
  position: relative;
  box-shadow: 2px 2px 9px rgba(178, 178, 178, 0.2);
}

.ant-list-item-meta-avatar {
  align-self: center;
}

.ant-layout-header {
  z-index: unset !important;
}

.ant-layout-sider-children {
  display: flex;
  flex-direction: column;
}

.ant-menu-inline {
  flex: 1 1;
}

.rounded {
  border: 0.75px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 12px;
}

.antd-header-dropdown {
  display: flex;
  align-items: baseline;
  margin-left: auto;
  margin-right: 20px;
  height: 50px;
}

.antd-header-dropdown > .ant-dropdown-trigger {
  display: flex;
  align-items: baseline;
  height: inherit;
}
.page-title .ant-breadcrumb span {
  margin: 0;
}
.page-title .ant-breadcrumb .ant-breadcrumb-separator {
  margin: 0 5px;
}
.ant-table thead > tr > th {
  color: #2f80ed;
  color: var(--primary);
}
.ant-form .ant-card-head-title {
  color: #000000;
}
.ant-form-item-label > label {
  color: #2b2b2b;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
}

.ant-upload-list-picture-card .ant-tooltip-inner {
  display: none;
}

.main-header-container {
  align-items: center !important;
  height: inherit;
  justify-content: space-between;
}

.page-content {
  margin-top: 2em;
}

.main-header {
  padding: 0 !important;
  margin: 0 !important;
  align-items: center !important;
  height: inherit;
  justify-content: flex-end !important;
  margin-right: 2em !important;
}

.main-header > .qr-one-container {
  display: flex;
  align-items: center !important;
  margin-left: 20px;
}
.main-header > .logout {
  cursor: pointer;
}

.main-header > .qr-one-container > .qr-one {
  padding: 0 !important;
  margin: 0 !important;
}

.main-header > .qr-one-container > p {
  margin: 0 !important;
  padding: 0 !important;
  line-height: normal;
  margin-left: 10px !important;
}

.mb-1 {
  margin-bottom: 1rem;
}

@media screen and (max-width: 576px) {
  .main-header {
    margin-right: 10px !important;
  }

  .main-header > .qr-one-container > p {
    font-size: 0.95em;
  }

  .main-header > .qr-one-container > .qr {
    display: none;
  }

  .main-header > .qr-one-container > .qr-text {
    display: none;
  }

  .ant-layout-header {
    position: fixed;
    z-index: 1000;
  }

  .jobs-page {
    margin-top: 70px;
  }
  .jobs-page .page-header-actions {
    margin: 5px 0 0 5px;
  }
  .extra-header.mob-tab .job-info {
    margin-top: 70px;
    flex-direction: column;
  }
  .extra-header.mob-tab .job-info .job-other-info {
    flex-direction: column;
  }
  .extra-header.mob-tab .job-info .job-other-info .job-info-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #dddddd;
    padding: 10px 0;
  }
  .extra-header.mob-tab .job-info .job-other-info .job-info-block span {
    margin-top: 0;
  }
  .extra-header.mob-tab + .page-content .page-sub-header {
    flex-direction: column;
  }
  .extra-header.mob-tab + .page-content .page-sub-header div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .extra-header.mob-tab + .page-content .page-sub-header div button {
    margin: 5px;
  }
  .job-profile-page .page-title {
    justify-content: start;
    padding: 10px 0 10px 15px;
  }
  .job-create-drawer .ant-drawer-content-wrapper {
    width: 320px !important;
  }
  .main-header-container {
    padding: 0 1em;
  }
}
.radioImage {
  display: inline-block;
  margin-bottom: 5px;
  vertical-align: top;
  height: 110px;
}
.radioImage {
  margin-right: 5px;
}
.radioImage .ant-card-body {
  padding: 10px !important;
  height: 110px;
  display: table-cell;
  vertical-align: middle;
}
.radioImage .ant-radio {
  opacity: 0;
}
.ant-radio-wrapper-checked.radioImage .ant-card.ant-card-bordered {
  border-color: #1597ff;
}
.ant-table-action-cell svg#Flat,
.ant-table-action-cell svg#Layer {
  color: inherit !important;
}
.otp-code-input > .ant-input-number-handler-wrap {
  display: none;
}
.otp-page {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.otp-page #otp-form {
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 10px;
  margin: 30px 0;
  box-shadow: 0 0 15px #cecece;
}
.main-message.pop-up-heading {
  font-size: 36px;
}
.pop-up-body {
  color: #828282;
  font-size: 15px;
}

.fallback-loader {
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-header-container {
  align-items: center !important;
  height: inherit;
  justify-content: space-between;
}

.page-content {
  margin-top: 2em;
}

.main-header {
  padding: 0 !important;
  margin: 0 !important;
  align-items: center !important;
  height: inherit;
  justify-content: flex-end !important;
  margin-right: 2em !important;
}

.main-header > .qr-one-container {
  display: flex;
  align-items: center !important;
  margin-left: 20px;
}
.main-header > .logout {
  cursor: pointer;
}

.main-header > .qr-one-container > .qr-one {
  padding: 0 !important;
  margin: 0 !important;
}

.main-header > .qr-one-container > p {
  margin: 0 !important;
  padding: 0 !important;
  line-height: normal;
  margin-left: 10px !important;
}

.mb-1 {
  margin-bottom: 1rem;
}

.route-name {
  display: flex;
  text-transform: capitalize;
  margin-bottom: 4px;
  line-height: 24px;
  flex: 3 1;
  margin-left: 8px;
}

@media screen and (max-width: 576px) {
  .main-header {
    margin-right: 10px !important;
  }

  .main-header > .qr-one-container > p {
    font-size: 0.95em;
  }

  .main-header > .qr-one-container > .qr {
    display: none;
  }

  .main-header > .qr-one-container > .qr-text {
    display: none;
  }

  .ant-layout-header {
    position: fixed;
    z-index: 1000;
  }
  .jobs-page {
    margin-top: 70px;
  }
  .jobs-page .page-header-actions {
    margin: 5px 0 0 5px;
  }
  .extra-header.mob-tab .job-info {
    margin-top: 70px;
    flex-direction: column;
  }
  .extra-header.mob-tab .job-info .job-other-info {
    flex-direction: column;
  }
  .extra-header.mob-tab .job-info .job-other-info .job-info-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    border-top: 1px solid #dddddd;
    padding: 10px 0;
  }
  .extra-header.mob-tab .job-info .job-other-info .job-info-block span {
    margin-top: 0;
  }
  .job-profile-page .page-title {
    justify-content: start;
    padding: 10px 0 10px 15px;
  }
  .job-detail-drawer .ant-drawer-content-wrapper {
    width: 320px !important;
  }
  .job-detail-drawer .ant-drawer-content-wrapper .drawer-header {
    flex-direction: column;
    justify-content: space-between;
  }
  .job-detail-drawer .ant-drawer-content-wrapper .drawer-header .header-info {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .job-create-drawer .ant-drawer-content-wrapper {
    width: 320px !important;
  }
  .job-detail-drawer
    .ant-drawer-content-wrapper
    .drawer-header
    .header-actions {
    width: 100%;
  }
  .main-header-container {
    padding: 0 1em;
  }
}

